import _ from "lodash";
import queryString from "query-string";
import dataUtils, {
  esgRatings,
  numberToRating,
  numberToEsgRating,
  ratings,
} from "./dataUtils";
import {
  ActiveStorageBlob,
  ActiveStorageBlobQuery,
  ActivismTrackerQuery,
  ActivistRiskScore,
  ActivistRiskScoreSupport,
  ActivistVulnerabilityQuery,
  AppOptions,
  Collection,
  Comment,
  CommentQuery,
  CompaniesSearchQuery,
  CompanyActivistRisk,
  CompanyDataResult,
  CompanyDetail,
  CompanyDirector,
  CompanyFinancial,
  CompanyInvestorFiling,
  CompanyPcaobAuditor,
  CompanySearchResult,
  ContactsQuery,
  ContentPage,
  CopilotConfigsQuestion,
  CopilotConfigsQuestionQuery,
  CopilotEdgarFormType,
  CopilotMeta,
  CopilotQuery,
  CopilotSandboxQuery,
  CopilotUserQuestionQuery,
  Diversity,
  DiversityGenderData,
  DiversityQuery,
  DocSearchQuery,
  ExecLeaverQuery,
  Expert,
  ExpertCategory,
  ExpertCompany,
  ExpertCompanyAvg,
  ExpertQuery,
  FileUploadOption,
  FormNpxOwner,
  FormNpxQuery,
  Governance,
  InvestorActivity,
  InvestorActivityCategory,
  InvestorActivityMeta,
  InvestorActivitySummary,
  InsiderPlanQuery,
  InstitutionalHolding,
  InstitutionalHoldingHistory,
  InstitutionalHoldingQuery,
  IpoMonitorQuery,
  LatestCompanyPrfQuery,
  MarketPerformanceHistogramFrame,
  MarketSnapshot,
  MarketSummary,
  MarketSummaryPremium,
  NpxData,
  NpxSummaryData,
  NpxMeta,
  NpxQuery,
  NpxVotingSummary,
  NpxFundDirectorNoVotes,
  NpxFundSayOnPayNoVotes,
  NpxFundSummaryData,
  NpxFundDirectorNoVotesData,
  NpxFundSayOnPayNoVotesData,
  PaginationData,
  PaginationQuery,
  PcaobAuditorAgg,
  PcaobAuditorCompany,
  PcaobAuditorServedByQuery,
  PcaobAuditorServedQuery,
  PeerDirectorAvg,
  PeerMeta,
  PeerPerformanceType,
  PeopleSearchQuery,
  PeopleSearchResult,
  PerformanceHistogramFrame,
  PerformanceReport,
  PerformanceReportMeta,
  PerformanceReportQuery,
  PersonDetail,
  PersonEsgPerformance,
  PersonNetworkItem,
  PersonRating,
  PfpAverage,
  PfpCeoCompensationAnalytics,
  PfpCompany,
  PrivateCompanyFunding,
  PrivateCompanyFundingQuery,
  RiskFactor10KAnalysis,
  RiskFactor10KAnalysisQuery,
  Role,
  RSS,
  SayOnPay,
  SayOnPayQuery,
  SayOnPaySummary,
  SearchQuery,
  SearchResult,
  SedarFiling,
  SedarFilingQuery,
  SharadarFundamentalDimension,
  SharadarTickerResult,
  StockPrice,
  TaggedPaginationData,
  TimeInterval,
  User,
  UserApiKey,
  UserFilingAlert,
  UserPeerGroup,
  PeerId,
  UserPeerGroupQuery,
  WarrantSummaryAverage,
  CompanyDirectorPeer,
  FilingsMonitorQuery,
  CompanyIpo,
  IpoExpertsQuery,
  TwitterDetail,
  UserBlobsQuery,
  UserSubscriptionAlert,
} from "./types";
import {
  encodeDiversitySearchQuery,
  encodeSpacQuery,
  snakeCase,
  stringifyFilingsMonitorQuery,
} from "./utils";

export interface ApiData<T> {
  data: T;
}

export interface ApiMetaData<T, U> {
  data: T;
  errors?: string;
  meta: U;
  status?: number;
}

export function baseURL() {
  if (window.location.hostname === "localhost") {
    if (window.location.port === "3000") {
      return "https://stage-api.boardroomalpha.com/api/v1";
    } else if (window.location.port === "3001") {
      return "http://localhost:3000/api/v1";
    }
  }
  const apiHost = window.location.hostname.replace(
    /^(.+)\.(\w+)\.(\w+)$/,
    "$1-api.$2.$3"
  );
  return `https://${apiHost}/api/v1`;
}

export function encodeQuery(q: Object): string {
  const snakeQ: Object = _.chain(q)
    .omitBy(_.isNil)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  return queryString.stringify(snakeQ);
}

export function encodeSearchQuery(q: Partial<SearchQuery>): string {
  const snakeQ: any = _.chain(q)
    .omitBy(_.isNil)
    .mapKeys((_value, key) => _.snakeCase(key))
    .value();
  snakeQ["flag[]"] = _.flatMap([snakeQ.flag]);
  snakeQ["industry[]"] = _.flatMap([snakeQ.industry]);
  snakeQ["sector[]"] = _.flatMap([snakeQ.sector]);
  snakeQ["company_size[]"] = _.flatMap([snakeQ.company_size]);

  delete snakeQ.flag;
  delete snakeQ.industry;
  delete snakeQ.sector;
  delete snakeQ.company_size;

  return queryString.stringify(snakeQ);
}

export function encodePeopleSearchQuery(q: Partial<PeopleSearchQuery>): string {
  const snakeQ: any = _.chain(q)
    .pickBy((v) => !!v)
    .mapKeys((_value, key) => _.snakeCase(key))
    .value();
  snakeQ["company_size[]"] = _.flatMap([snakeQ.company_size]);
  snakeQ["ids[]"] = _.flatMap([snakeQ.ids]);
  snakeQ["industry[]"] = _.flatMap([snakeQ.industry]);
  snakeQ["race[]"] = _.flatMap([snakeQ.race]);
  snakeQ["sector[]"] = _.flatMap([snakeQ.sector]);

  delete snakeQ.company_size;
  delete snakeQ.ids;
  delete snakeQ.industry;
  delete snakeQ.race;
  delete snakeQ.sector;

  return queryString.stringify(snakeQ);
}

function mergeQueryRanges<T = PeopleSearchQuery | CompaniesSearchQuery>(
  source: Partial<T>,
  target: Partial<T>,
  keys: Array<[keyof T, keyof T]>
) {
  for (const [a, b] of keys) {
    const aval = source[a];
    const bval = source[b];
    if (typeof aval !== "number") continue;
    if (typeof bval !== "number") continue;
    if ((a as string).includes("esg")) {
      if ((a as string).includes("Overall")) {
        if (aval !== 0 || bval !== esgRatings.length - 1) {
          target[a] = encodeURI(numberToEsgRating(aval)) as any;
          target[b] = encodeURI(numberToEsgRating(bval)) as any;
        }
      } else {
        if (aval !== 0 || bval !== 10) {
          target[a] = aval as any;
          target[b] = bval as any;
        }
      }
    }
    if (
      !_.includes(a as string, "esg") &&
      (aval !== 0 || bval !== ratings.length - 1)
    ) {
      target[a] = encodeURI(numberToRating(aval)) as any;
      target[b] = encodeURI(numberToRating(bval)) as any;
    }
  }
}
function mergeQueryRangesV2<T = CompaniesSearchQuery | PeopleSearchQuery>(
  source: Partial<T>,
  target: Partial<T>,
  keys: Array<[keyof T, keyof T, number?, number?]>
) {
  for (const [a, b, min, max] of keys) {
    const aval = source[a];
    const bval = source[b];
    if (typeof aval !== "number" || typeof bval !== "number") continue;
    if ((a as string).includes("esg")) {
      if ((a as string).includes("Overall")) {
        if (aval !== 0 || bval !== esgRatings.length - 1) {
          target[a] = encodeURI(numberToEsgRating(aval)) as any;
          target[b] = encodeURI(numberToEsgRating(bval)) as any;
        }
      } else {
        if (aval !== 0 || bval !== 10) {
          target[a] = aval as any;
          target[b] = bval as any;
        }
      }
      continue;
    }
    if (
      (a as string).includes("alpha") ||
      (a as string).includes("companyScore") ||
      (a as string).includes("Rating")
    ) {
      if (aval !== 0 || bval !== ratings.length - 1) {
        target[a] = encodeURI(numberToRating(aval)) as any;
        target[b] = encodeURI(numberToRating(bval)) as any;
      }
      continue;
    }
    if (aval === bval) {
      target[a] = aval as any;
      target[b] = bval as any;
    } else {
      if (aval !== min) target[a] = aval as any;
      if (bval !== max) target[b] = bval as any;
    }
  }
}

function authHeader(): any {
  if (localStorage.authToken) {
    return {
      Authorization: `Bearer ${localStorage.authToken}`,
    };
  } else {
    return {};
  }
}

async function apiFetch(path: string, init?: RequestInit) {
  const fullInit: RequestInit = {
    credentials: "include",
    headers: {
      ...authHeader(),
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      "X-Forwarded-Proto": window.location.port === "3001" ? "http" : "https",
    },
    ...init,
  };

  const response = await fetch(baseURL() + path, fullInit);
  if (response.status > 400) {
    switch (response.status) {
      case 401: // user is unauthorized
        delete localStorage.authToken;
        window.location.assign("/");
        break;
      case 423: // user exceeds max_follows
        window.location.assign("/people");
        break;
    }
  }
  return response.json();
}

async function authCheck(): Promise<any> {
  return await apiFetch("/auth_check");
}

async function claimProfile(id: string): Promise<any> {
  return await apiFetch(`/people/${id}/claim_profile`, { method: "put" });
}

async function followCompany(
  following: boolean,
  companyId: number | string,
  collectionId?: number | null
) {
  const verb = following ? "follow" : "unfollow";
  return await apiFetch(`/companies/${companyId}/${verb}`, {
    body: JSON.stringify({ collection_id: collectionId }),
    method: "put",
  });
}

async function followPerson(
  following: boolean,
  personId: number | string,
  collectionId?: number | null
) {
  const verb = following ? "follow" : "unfollow";
  return await apiFetch(`/people/${personId}/${verb}`, {
    body: JSON.stringify({ collection_id: collectionId }),
    method: "put",
  });
}

async function postApiKey(): Promise<{
  data?: UserApiKey;
  exceed_max_generated_api_keys: boolean;
  success: boolean;
}> {
  return await apiFetch("/user_api_keys", {
    method: "post",
  });
}

async function putApiKey(id: number): Promise<{
  data?: UserApiKey;
  exceed_max_active_api_keys: boolean;
  success: boolean;
}> {
  return await apiFetch("/user_api_keys", {
    body: JSON.stringify({ id }),
    method: "put",
  });
}

async function getSocialMedia(id: string | number, signal: AbortSignal) {
  return await apiFetch(`/social_media/social_accounts/${id}`, { signal });
}

async function forgotPwEmail(email: string) {
  return await apiFetch(
    `/sessions/change_password?email=${encodeURIComponent(email)}`
  );
}

async function getAppOptions(): Promise<ApiData<AppOptions>> {
  return await apiFetch("/option-values");
}

async function getLandingPageOptions() {
  return await apiFetch("/user/landing_page_options");
}

async function getBAFeed(): Promise<ApiData<RSS[]>> {
  return await apiFetch("/rss/feed");
}

async function getBoardRefreshment(id: string | number | undefined) {
  return await apiFetch(`/companies/${id}/board_refreshment?per_page=64`);
}

async function getGovernance(
  id: number | string,
  peerId: null | number | string | undefined,
  peerType: PeerPerformanceType | undefined
): Promise<{
  data: Governance;
  meta: { peer_id: number | string; peer_type: PeerPerformanceType } | null;
}> {
  const q = encodeQuery({ peerId, peerType });
  return await apiFetch(`/companies/${id}/governance?${q}`);
}

async function getNotifications(
  reference: number | string = "",
  collectionId: number | null = null,
  signal: AbortSignal
) {
  return await apiFetch(
    `/notifications?&collection_id=${collectionId}&reference=${reference}`,
    { signal }
  );
}

/*async function getActivistInvestorFilings(
  id: string | number | undefined,
  page: number,
  signal: AbortSignal,
  per_page: number = 50
) {
  return await apiFetch(
    `/companies/${id}/activist_inv_filings?page=${page}&per_page=${per_page}`,
    { signal }
  );
}*/

async function getSedarFilings(
  query: SedarFilingQuery
): Promise<ApiMetaData<SedarFiling[], PaginationData>> {
  const snakeQ: any = _.chain(query)
    .omitBy(_.isNil)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  if (!_.isEmpty(snakeQ.groups)) {
    snakeQ["groups[]"] = _.flatMap([snakeQ.groups]);
    delete snakeQ.groups;
  }
  return await apiFetch(`/region_ca/filings?${queryString.stringify(snakeQ)}`);
}

async function getSedarFormGroupOptions(): Promise<
  ApiData<Array<{ label: string; value: string }>>
> {
  return await apiFetch("/options/sedar_form_groups");
}

async function getSignupAccountDetails(
  accountCode: string,
  signal: AbortSignal
) {
  return await apiFetch(`/user/account_signup/${accountCode}`, { signal });
}

async function getSpacCalendar(query: any, signal: AbortSignal) {
  const q = queryString.stringify(snakeCase(query));
  return await apiFetch(`/spacs/spac_calendar?${q}`, { signal });
}

async function getSpacDomiciles(view: string) {
  return await apiFetch(
    `/spacs/spac_domiciles?status=${view === "pre_trans" ? "Pre-Transaction" : "Announced Transaction"
    }`
  );
}

async function getSpacMarketSummary() {
  const res = await apiFetch("/spacs/market_summary");
  if (res.status === 200) {
    res.premium_announced.forEach((d: MarketSummaryPremium) => {
      d.date = dataUtils.toEastCoastDate(d.date as string);
    });
    res.premium_pre_trans.forEach((d: MarketSummaryPremium) => {
      d.date = dataUtils.toEastCoastDate(d.date as string);
    });
  }
  return res;
}

async function getSpacMetricYearOptions(): Promise<
  Array<{ label: number; value: number }>
> {
  return await apiFetch("/options/spac_metric_years");
}

async function getSpacWarrantSummary() {
  const res = await apiFetch("/spacs/warrant_summary");
  if (res.status === 200) {
    res.warrant_chart_pre.forEach((d: WarrantSummaryAverage) => {
      d.date = dataUtils.toEastCoastDate(d.date as string);
    });
    res.warrant_chart_post.forEach((d: WarrantSummaryAverage) => {
      d.date = dataUtils.toEastCoastDate(d.date as string);
    });
  }
  return res;
}

async function getSpacList(query: any, signal: AbortSignal) {
  const q = encodeSpacQuery(query);
  return await apiFetch(`/spacs/spac_list_view?${q}`, { signal });
}

async function getSpacOptions(signal: AbortSignal) {
  return await apiFetch(`/spacs/options`, { signal });
}

async function getSpacSponsor(
  sponsor: string,
  signal: AbortSignal,
  isPopup: boolean = false
) {
  return await apiFetch(`/spacs/sponsor/${sponsor}?is_popup=${isPopup}`, {
    signal,
  });
}

async function getSpacUnderwriters(signal: AbortSignal) {
  return await apiFetch("/spacs/underwriters", { signal });
}

async function getActivismTracker(query: ActivismTrackerQuery) {
  const snakeQ: any = _.chain(query)
    .pickBy((v) => !!v)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();

  snakeQ["industries[]"] = _.flatMap([snakeQ.industries]);
  snakeQ["sectors[]"] = _.flatMap([snakeQ.sectors]);
  snakeQ["sizes[]"] = _.flatMap([snakeQ.sizes]);

  delete snakeQ.industries;
  delete snakeQ.sectors;
  delete snakeQ.sizes;

  return await apiFetch(
    `/tools/activism_filing_tracker?${queryString.stringify(snakeQ)}`
  );
}

async function getActivistList() {
  return await apiFetch("/tools/activist");
}

async function getActivist13F(
  id: number | string,
  date: string,
  signal: AbortSignal
) {
  if (date) {
    return await apiFetch(`/companies/${id}/activist_13f?date=${date}`, {
      signal,
    });
  } else {
    return [];
  }
}

async function getActivist13FMeta(id: number | string, signal: AbortSignal) {
  return await apiFetch(`/companies/${id}/activist_13f_meta`, { signal });
}

async function getActivistAffiliates(id: number | string, signal: AbortSignal) {
  return await apiFetch(`/companies/${id}/activist_affiliates`, { signal });
}

async function getActivistLatestFilings(cik: number, signal: AbortSignal) {
  return await apiFetch(`/companies/${cik}/activist_latest_filings`, {
    signal,
  });
}

async function getActivistVulnerabilityMonitor(
  query: ActivistVulnerabilityQuery,
  signal: AbortSignal
) {
  return await apiFetch(
    `/tools/activist_vulnerability?${queryString.stringify(query)}`,
    { signal }
  );
}

async function getCeoPayComparison(
  id: string | number,
  query: Object,
  signal: AbortSignal
): Promise<{
  data: PfpCeoCompensationAnalytics;
  meta: PeerMeta | null;
}> {
  return await apiFetch(
    `/companies/${id}/ceo_pay_comparison?${queryString.stringify(query)}`,
    { signal }
  );
}

async function getCommentChildren(id: number): Promise<ApiData<Comment[]>> {
  return await apiFetch(`/comments/${id}/children`);
}

async function getComments(
  id: string,
  type: "companies" | "people"
): Promise<ApiData<Comment[]>> {
  return await apiFetch(`/${type}/${id}/comments`);
}

async function getCompany(
  id: number | string
): Promise<ApiMetaData<CompanyDetail, TaggedPaginationData>> {
  const res: ApiMetaData<
    CompanyDetail,
    TaggedPaginationData & { following: boolean }
  > = await apiFetch(`/companies/${id}`);
  if (res.status === 200) {
    dataUtils.normalizeCompany(res.data);
    res.data.following = res.meta.following;
  }
  return Promise.resolve(res);
}

async function getCompanyActivistRisk(
  cik: number
): Promise<CompanyActivistRisk> {
  const res = await apiFetch(`/companies/${cik}/activist_risk`);
  const focal: Array<[number, number]> = [];
  const peer: Array<[number, number]> = [];
  res.scores.forEach((s: ActivistRiskScore) => {
    const date: number = dataUtils.toEastCoastDate(s.score_interval);
    focal.push([date, s.risk_score]);
    peer.push([date, s.risk_score_percent_rank]);
  });
  // order cagr_6m before cagr_1y
  const idx: number = _.findIndex(
    res.supports,
    (s: ActivistRiskScoreSupport) => s.metric === "cagr_6m"
  );
  const supports: ActivistRiskScoreSupport[] = _.chain(res.supports)
    .take(
      // sub arr containing all metrics from metric_types 'Fundamental' & 'Governance'
      _.findIndex(
        res.supports,
        (s: ActivistRiskScoreSupport) =>
          s.metric === "ceo_tenure_yrs_since_start"
      ) + 1
    )
    .concat(res.supports[idx]) // append cagr_6m
    .concat(
      // append 'Returns' sub arr
      _.slice(
        // sub arr containting all metrics from metric_type 'Returns' (excluding cagr_6m)
        res.supports,
        _.findIndex(
          res.supports,
          (s: ActivistRiskScoreSupport) => s.metric === "cagr_1y"
        ),
        idx
      )
    )
    .value();
  return { scores: { focal, peer }, supports };
}

async function getCompanyByUid(id: string) {
  return await apiFetch(`/companies/find_by_uid?id=${id}`);
}

async function getCompanyDefaultPeerGroup(
  id: number
): Promise<ApiData<PeerId>> {
  return await apiFetch(`/companies/${id}/default_peer_group`);
}

async function getCompanyFinancials(
  cik: number,
  dimension: SharadarFundamentalDimension,
  maxYear: number,
  minYear: number
): Promise<ApiData<CompanyFinancial[]>> {
  const q: string = encodeQuery({ dimension, maxYear, minYear });
  return await apiFetch(`/companies/${cik}/financials?${q}`);
}

async function getCompanyInsuranceMeta(cik: number): Promise<
  ApiData<{
    company_name: string;
    date: string;
    marketcap: null | number;
    ticker: string;
  }>
> {
  return await apiFetch(`/companies/${cik}/insurance_meta`);
}

async function getCompanyMeta(cik: string) {
  return await apiFetch(`/companies/${cik}/meta`);
}

async function getPersonMeta(cik: string) {
  return await apiFetch(`/people/${cik}/meta`);
}

async function getCompanyMarketSnapshot(
  cik: number
): Promise<ApiData<MarketSnapshot>> {
  return await apiFetch(`/companies/${cik}/market_snapshot`);
}

async function getCopilotCannedQuestions(query: {
  formType: string;
  userId?: number;
}): Promise<ApiData<CopilotConfigsQuestion[]>> {
  return await apiFetch(
    `/copilot_configs_questions/canned_questions?${encodeQuery(query)}`
  );
}

async function getCopilotCompanyFiling(
  query: CopilotQuery
): Promise<{ data: string; meta: CopilotMeta }> {
  return await apiFetch(`/copilots/company_filing?${encodeQuery(query)}`);
}

async function getCopilotFileUpload(
  query: CopilotSandboxQuery
): Promise<ApiData<string>> {
  return await apiFetch(`/copilots/file_upload?${encodeQuery(query)}`);
}

async function getCopilotFilingIsScraped(
  ean: string
): Promise<ApiData<boolean>> {
  return await apiFetch(
    `/copilots/is_scraped?${queryString.stringify({ ean })}`
  );
}

async function getCopilotFormTypeOptions(
  companyCik?: number
): Promise<ApiData<CopilotEdgarFormType[]>> {
  return await apiFetch(
    `/options/copilot_edgar_form_types?${encodeQuery({ companyCik })}`
  );
}

async function deleteCopilotConfigsQuestion(id: number) {
  return await apiFetch(`/copilot_configs_questions/${id}`, {
    method: "delete",
  });
}

async function postCopilotConfigsQuestion(query: CopilotConfigsQuestionQuery) {
  const snakeCased = _.chain(query)
    .omit("id")
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  return await apiFetch("/copilot_configs_questions", {
    body: JSON.stringify(snakeCased),
    method: "post",
  });
}

async function putCopilotConfigsQuestion(query: CopilotConfigsQuestionQuery) {
  const snakeCased = _.chain(query)
    .omit("id")
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  return await apiFetch(`/copilot_configs_questions/${query.id}`, {
    body: JSON.stringify(snakeCased),
    method: "put",
  });
}

async function getCopilotUserQuestions(
  query?: CopilotUserQuestionQuery
): Promise<ApiData<CopilotConfigsQuestion[]>> {
  return await apiFetch(
    `/copilot_configs_questions/user_questions?${queryString.stringify(
      _.pickBy(query, (value: boolean | string | undefined) => value)
    )}`
  );
}

async function getFundStockPrices(
  category: string,
  ticker: string,
  startDate?: string,
  endDate?: string
): Promise<ApiData<StockPrice[]>> {
  const res = await apiFetch(
    `/funds/${ticker}/eod_prices?${encodeQuery({
      category,
      endDate,
      startDate,
    })}`
  );
  res.data.forEach((p: any) => {
    p.price_date = dataUtils.toEastCoastDate(p.price_date);
  });
  return Promise.resolve(res);
}

async function getCompanyStockPrices(
  id: number | string,
  startDate?: string,
  endDate?: string
): Promise<ApiData<StockPrice[]>> {
  const q = encodeQuery({ endDate, startDate });
  const response = await apiFetch(`/companies/${id}/eod_prices?${q}`);
  response.data.forEach((d: any) => {
    d.price_date = dataUtils.toEastCoastDate(d.price_date);
  });
  return Promise.resolve(response);
}

async function getCompanyEndOfWeekStockPrices(
  tickers: string[],
  startDate?: string,
  endDate?: string
): Promise<ApiData<StockPrice[]>> {
  const q = queryString.stringify({
    end_date: endDate || "",
    start_date: startDate || "",
    "tickers[]": tickers,
  });
  const response: ApiData<StockPrice[]> = await apiFetch(
    `/companies/eow_prices?${q}`
  );
  response.data.forEach((d: any) => {
    d.price_date = dataUtils.toEastCoastDate(d.price_date);
  });
  response.data = _.sortBy(response.data, (d) => d.price_date);
  return Promise.resolve(response);
}

async function getEsgSignals(
  cik: number | string,
  query: Object,
  type: string,
  isCompany: boolean
) {
  const snakeQ = _.chain(query)
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  const q = queryString.stringify(_.merge(snakeQ, { type }));

  return await apiFetch(
    `/${isCompany ? "companies" : "people"}/${cik}/esg_signals?${q}`
  );
}

async function getCompanyEsgHistory(
  id: number | string,
  personCik: string,
  role: "CEO" | "CFO" | "Director"
) {
  return await apiFetch(
    `/companies/${id}/esg_history?person_cik=${personCik}&role=${role}`
  );
}

async function getCompanyEsgPerformance(
  id: number | string,
  role: "CEO" | "CFO" | "Director"
): Promise<ApiData<PersonEsgPerformance[]>> {
  return await apiFetch(`/companies/${id}/esg_performance?role=${role}`);
}

async function getCompanyInterlock(id: number | string) {
  return await apiFetch(`/companies/${id}/interlock`);
}

async function getCompanyIpos(
  isPreIpo: boolean,
  query: IpoMonitorQuery
): Promise<ApiData<CompanyIpo[]>> {
  const snakeQ: any = _.chain(query)
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  snakeQ["industries[]"] = _.flatMap([snakeQ.industries]);
  delete snakeQ.industries;
  const q = queryString.stringify(snakeQ);
  return await apiFetch(
    `/tools/ipos?status=${isPreIpo ? "Pre-IPO" : "Withdrawn"}&${q}`
  );
}

async function getCompanyNpx(
  query: FormNpxQuery,
  signal: AbortSignal
): Promise<NpxData> {
  const res: {
    data: NpxVotingSummary[];
    meta: NpxMeta;
  } = await apiFetch(
    `/companies/${query.issuer}/npx?${encodeQuery({ owner: query.owner })}`,
    { signal }
  );
  const normalized = _.chain(res.data)
    .groupBy((n: NpxVotingSummary) => n.group)
    .mapValues((na: NpxVotingSummary[]) =>
      _.chain(na)
        .orderBy(["year", "person_name", "proposal"], ["desc", "asc", "asc"])
        .groupBy((n) => n.person_name || n.proposal)
        .value()
    )
    .mapValues((no: { [key: string]: NpxVotingSummary[] }) =>
      _.mapValues(no, (na: NpxVotingSummary[]) => _.keyBy(na, (n) => n.year))
    )
    .value();
  return { data: normalized, meta: res.meta };
}

async function getOwnerNpxSummary(
  query: FormNpxQuery,
  signal: AbortSignal
): Promise<NpxSummaryData> {
  const res: {
    data: NpxFundSummaryData[];
  } = await apiFetch(
    `/funds/${query.owner}/npx/summary`,
    { signal }
  );
  return { data: res.data };
}

async function getOwnerNpxDirectorNoVotes(
  owner_cik: number | undefined,
  query: PaginationQuery,
  signal: AbortSignal
): Promise<NpxFundDirectorNoVotesData> {
  const res: {
    data: NpxFundDirectorNoVotes[];
    meta: PaginationData;
  } = await apiFetch(
    `/funds/${owner_cik}/npx/director_no_votes?${encodeQuery(query)}`,
    { signal }
  );
  return { data: res.data, meta: res.meta };
}

async function getOwnerNpxSayOnPayNoVotes(
  owner_cik: number | undefined,
  query: PaginationQuery,
  signal: AbortSignal
): Promise<NpxFundSayOnPayNoVotesData> {
  const res: {
    data: NpxFundSayOnPayNoVotes[];
    meta: PaginationData;
  } = await apiFetch(
    `/funds/${owner_cik}/npx/sayonpay_no_votes?${encodeQuery(query)}`,
    { signal }
  );
  return { data: res.data, meta: res.meta };
}

async function getCompanyPcaobAuditorHistory(
  cik: number,
  query: PaginationQuery
): Promise<ApiMetaData<CompanyPcaobAuditor[], PaginationData>> {
  return await apiFetch(
    `/companies/${cik}/pcaob_auditor_history?${encodeQuery(query)}`
  );
}

async function getCompanyProviders(company_cik: number) {
  return await apiFetch(`/companies/${company_cik}/providers`);
}

async function getContacts(query: ContactsQuery, canadian = false) {
  const snakeQ: any = _.chain(query)
    .pickBy((v) => !!v)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();

  snakeQ["industries[]"] = _.flatMap([snakeQ.industries]);
  snakeQ["sectors[]"] = _.flatMap([snakeQ.sectors]);

  delete snakeQ.industries;
  delete snakeQ.sectors;

  const path: string = canadian ? "/region_ca" : "/tools";
  return await apiFetch(`${path}/contacts?${queryString.stringify(snakeQ)}`);
}

async function getNpxDetail(
  query: NpxQuery,
  signal: AbortSignal,
  ticker: string
) {
  return await apiFetch(
    `/companies/${ticker}/npx_detail?${queryString.stringify(query)}`,
    { signal }
  );
}

async function getPcaobAuditorCompaniesServed(
  query: PcaobAuditorServedQuery
): Promise<ApiData<PcaobAuditorCompany[]>> {
  const snakeQ: any = _.chain(query)
    .omitBy(_.isNil)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  snakeQ["flags[]"] = _.flatMap([snakeQ.flags]);
  snakeQ["partners[]"] = _.flatMap([snakeQ.partners]);
  snakeQ["years[]"] = _.flatMap([snakeQ.years]);
  delete snakeQ.flags;
  delete snakeQ.partners;
  delete snakeQ.years;
  return await apiFetch(
    `/tools/pcaob_auditor_companies_served?${queryString.stringify(snakeQ)}`
  );
}

async function getPcaobAuditorCompaniesServedBy(
  query: PcaobAuditorServedByQuery
): Promise<ApiData<PcaobAuditorAgg[]>> {
  const omitted = _.omitBy(query, _.isNil);
  omitted["flags[]"] = _.flatMap([omitted.flags]);
  omitted["partners[]"] = _.flatMap([omitted.partners]);
  omitted["years[]"] = _.flatMap([omitted.years]);
  delete omitted.flags;
  delete omitted.partners;
  delete omitted.years;
  return await apiFetch(
    `/tools/pcaob_auditor_companies_served_by?${queryString.stringify(omitted)}`
  );
}

async function getPcaobAuditorCountryOptions(): Promise<
  ApiData<Array<{ label: string; value: string }>>
> {
  return await apiFetch(`/options/pcaob_auditor_countries`);
}

async function getPcaobAuditorFirmOptions(
  country: string
): Promise<ApiData<Array<{ label: string; value: number }>>> {
  return await apiFetch(`/options/pcaob_auditor_firms?country=${country}`);
}

async function getPcaobAuditorPartnerOptions(
  firm: number
): Promise<ApiData<Array<{ label: string; value: number }>>> {
  return await apiFetch(`/options/pcaob_auditor_partners?firm=${firm}`);
}

async function getPcaobAuditorYearOptions(
  firm: number,
  partners: number[]
): Promise<ApiData<Array<{ label: number; value: number }>>> {
  const omitted = _.omitBy({ firm, partners }, _.isNil);
  omitted["partners[]"] = _.flatMap([omitted.partners]);
  delete omitted.partners;
  return await apiFetch(
    `/options/pcaob_auditor_years?${queryString.stringify(omitted)}`
  );
}

async function getPeerDirectorAvg(
  id: number | string
): Promise<ApiData<PeerDirectorAvg>> {
  return await apiFetch(`/companies/${id}/peer_director_avg`);
}

async function getPeersPerformance(
  id: number | string,
  query: Object,
  source: "companies" | "people",
  signal: AbortSignal
): Promise<{
  data: CompanyDirectorPeer[];
  meta: PeerMeta | null;
}> {
  return await apiFetch(
    `/${source}/${id}/sector_peers?${queryString.stringify(query)}`,
    { signal }
  );
}

async function getPrivateCompanyFunding(
  query: PrivateCompanyFundingQuery
): Promise<ApiMetaData<PrivateCompanyFunding[], PaginationData>> {
  const snakeQ: any = _.chain(query)
    .omitBy(_.isNil)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  snakeQ["industries[]"] = _.flatMap([snakeQ.industries]);
  snakeQ["states[]"] = _.flatMap([snakeQ.states]);
  delete snakeQ.industries;
  delete snakeQ.states;
  return await apiFetch(
    `/tools/private_company_funding?${queryString.stringify(snakeQ)}`
  );
}

async function getPrivateCompanyFundingMeta() {
  return await apiFetch("/tools/private_company_funding_meta");
}

async function getContentPage(id: string): Promise<ApiData<ContentPage>> {
  return await apiFetch(`/content_pages/show/${id}`);
}

async function getPersonEsgPerformance(id: string) {
  return await apiFetch(`/people/${id}/esg_performance`);
}

async function getPersonMentions(id: string | undefined, query: Object) {
  const snakeQ = _.chain(query)
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  const q = queryString.stringify(snakeQ);
  return await apiFetch(`/people/${id}/mentions?${q}`);
}

async function getPersonMentions502(id: string | undefined, query: Object) {
  const snakeQ = _.chain(query)
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  const q = queryString.stringify(snakeQ);
  return await apiFetch(`/people/${id}/mentions502?${q}`);
}

async function getCompanyMentions(id: string | number, query: Object) {
  return await apiFetch(`/companies/${id}/mentions?${encodeQuery(query)}`);
}

async function getDealScreener(signal: AbortSignal) {
  return await apiFetch("/spacs/deal_screener", { signal });
}

async function getDensityPlots(
  interval: TimeInterval,
  dataFrameType: "full" | "trim"
): Promise<any> {
  const q = queryString.stringify({
    ...interval,
    data_frame_type: dataFrameType,
  });

  return await apiFetch(`/density-plots?${q}`);
}

async function getPerson(
  id: string,
  role: Role
): Promise<ApiMetaData<PersonDetail, TaggedPaginationData>> {
  const res: ApiMetaData<
    PersonDetail,
    TaggedPaginationData & { following: boolean }
  > = await apiFetch(`/people/${id}?role=${role}`);
  if (res.status === 200) {
    dataUtils.normalizePerson(res.data);
    res.data.following = res.meta.following;
  }
  return Promise.resolve(res);
}

async function getPersonAssociatedOrg(id: string): Promise<ApiData<string[]>> {
  return await apiFetch(`/people/${id}/associated_org`);
}

async function getArticle(
  id: string,
  source: string,
  reference: string,
  signal: AbortSignal
) {
  return await apiFetch(
    `/feed/${source}/?id=${id}${!!reference ? `&reference=${reference}` : ""} `,
    {
      signal,
    }
  );
}

async function getPersonInsiderPerformance(id: string) {
  return await apiFetch(`/people/${id}/insider_activity_performance`);
}

async function getDiversityGraph(
  query: DiversityQuery,
  signal: AbortSignal
): Promise<{
  data: DiversityGenderData[];
}> {
  const q = encodeDiversitySearchQuery(query);
  return await apiFetch(`/tools/diversity_graph?${q}`, {
    signal,
  });
}

async function getDiversityTable(
  query: DiversityQuery,
  signal: AbortSignal,
  perPage: number = 250
): Promise<{
  data: Diversity[];
  meta: PaginationData;
}> {
  const q = encodeDiversitySearchQuery(query);
  return await apiFetch(`/tools/diversity_table?${q}&per_page=${perPage}`, {
    signal,
  });
}

async function getHeatMap() {
  return await apiFetch("/spacs/heat_map");
}

async function searchDocs(
  query: DocSearchQuery,
  category: number | string,
  page: number,
  perPage: number,
  source: string,
  signal: AbortSignal
): Promise<ApiMetaData<any, PaginationData>> {
  const newQ: Partial<any> = {
    companies: query.companies,
    dateRange: query.dateRange,
    topics: query.topics,
    types: category,
    searchTerm: query.searchTerm,
  };
  function encodeQuery(q: Partial<DocSearchQuery>) {
    const snakeQ: any = _.chain(q)
      .omitBy(_.isNil)
      .mapKeys((_value, key) => _.snakeCase(key))
      .value();

    snakeQ["companies[]"] = _.flatMap(snakeQ.companies, (c) => c.cik);
    snakeQ["types[]"] = _.flatMap([snakeQ.types]);
    snakeQ["topics[]"] = _.flatMap([snakeQ.topics]);

    delete snakeQ.companies;
    delete snakeQ.types;
    delete snakeQ.topics;

    return queryString.stringify(snakeQ);
  }

  const q = encodeQuery(newQ);

  return await apiFetch(
    `/${source}/documents?${q}&page=${page}&per_page=${perPage}`,
    { signal }
  );
}

async function getActivistInvestorFilings(
  id: string | number | undefined,
  is13D: boolean,
  page: number,
  signal: AbortSignal,
  perPage: number = 50
) {
  return await apiFetch(
    `/companies/${id}/activist_inv_filings?page=${page}&per_page=${perPage}&is_13d=${is13D ? true : ""
    }`,
    { signal }
  );
}

async function getPodcast() {
  return await apiFetch("/rss/podcast");
}

async function getCompanyInstitutionalHoldingHistory(query: {
  cik: number;
  id: number;
  manager?: null | string;
}): Promise<
  ApiMetaData<
    InstitutionalHoldingHistory[],
    { company_name: string; ticker: string }
  >
> {
  return await apiFetch(
    `/companies/${query.id
    }/institutional_holding_history?${queryString.stringify(
      _.omitBy(query, _.isNil)
    )}`
  );
}

async function getCompanyInstitutionalHoldings(
  query: InstitutionalHoldingQuery,
  signal: AbortSignal
): Promise<ApiMetaData<InstitutionalHolding[], string[]>> {
  const params: string = queryString.stringify(
    _.chain(query).omit("cik").omitBy(_.isNil).value()
  );
  return await apiFetch(
    `/companies/${query.cik}/institutional_holdings?${params}`,
    {
      signal,
    }
  );
}

async function getCompanyInvestorFiling(
  id: string | number,
  page: number,
  signal: AbortSignal
): Promise<ApiMetaData<CompanyInvestorFiling[], PaginationData>> {
  return await apiFetch(
    `/companies/${id}/investor_filings_data?page=${page}&per_page=50`,
    { signal }
  );
}

async function getComparisonReport(
  ids: string[],
  type: string,
  focal?: string
) {
  const obj: { [key: string]: string[] } = {};
  obj["ids[]"] = _.flatMap([ids]);
  return await apiFetch(
    `/tools/comparison_report/${focal}?${queryString.stringify(
      obj
    )}&type=${type}`
  );
}

async function getFileUploadLabelOptions(): Promise<
  ApiData<Array<{ label: string; value: string }>>
> {
  return await apiFetch("/options/file_upload_labels");
}

async function getFileUploadOptions(): Promise<ApiData<FileUploadOption[]>> {
  return await apiFetch("/options/file_uploads");
}

async function getFilingAlertFormTypes() {
  return await apiFetch("/options/filing_alert_form_types");
}

async function getFilingDiff(primaryEan: string, secondaryEan: string) {
  return await apiFetch(`/filings/filing_diff/${primaryEan}/${secondaryEan}`);
}

async function getFilingSourceURL(params: { ean: string }) {
  return await apiFetch(`/filings/${params.ean}`);
}

async function getFilings(
  query: FilingsMonitorQuery,
  signal: AbortSignal
): Promise<ApiMetaData<any, PaginationData>> {
  return await apiFetch(
    `/tools/filings?${stringifyFilingsMonitorQuery(query)}`,
    {
      signal,
    }
  );
}

async function getPerformance(
  id: string,
  query: PerformanceReportQuery,
  tool: boolean,
  signal: AbortSignal
): Promise<{
  data: PerformanceReport[];
  meta: PerformanceReportMeta;
}> {
  const basePath: string = tool
    ? `/tools/scorecard/${id}`
    : `/people/${id}/scorecard`;

  const snakeQ: Object = _.chain(query)
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  return await apiFetch(`${basePath}?${queryString.stringify(snakeQ)}`, {
    signal,
  });
}

async function getPerformanceReportOptions(id: string) {
  return await apiFetch(`/people/${id}/performance_report_options`);
}

async function getPersonNetwork(
  id: string
): Promise<ApiData<PersonNetworkItem[]>> {
  return await apiFetch(`/people/${id}/network`);
}

async function getPersonTenures(id: string, role: string, signal: AbortSignal) {
  return await apiFetch(
    `/people/${id}/tenures?${queryString.stringify({ role })}`,
    { signal }
  );
}

async function getPersonTsrSummary(
  companyCik: string | number,
  personCik: string,
  role: string,
  startDate: string,
  signal: AbortSignal
) {
  return await apiFetch(
    `/people/${personCik}/tsr_summary?company_cik=${companyCik}&role=${role}&start_date=${startDate}`,
    { signal }
  );
}

async function getFollowedCompanies(
  collectionId: number | null | undefined,
  query: CompaniesSearchQuery,
  signal: AbortSignal
): Promise<ApiMetaData<CompanySearchResult[], PaginationData>> {
  const newQ: Partial<CompaniesSearchQuery> = {
    ceoAgeMax: query.ceoAgeMax,
    ceoAgeMin: query.ceoAgeMin,
    ceoGender: query.ceoGender,
    ceoTenureMax: query.ceoTenureMax,
    ceoTenureMin: query.ceoTenureMin,
    cfoAgeMax: query.cfoAgeMax,
    cfoAgeMin: query.cfoAgeMin,
    cfoTenureMax: query.cfoTenureMax,
    cfoTenureMin: query.cfoTenureMin,
    collectionId,
    companySize: query.companySize,
    industry: query.industry,
    order: query.order,
    orderBy: query.orderBy,
    page: query.page,
    perPage: Math.min(query.perPage),
    sector: query.sector,
  };
  mergeQueryRanges(query, newQ, [
    ["ceoRatingMin", "ceoRatingMax"],
    ["cfoRatingMin", "cfoRatingMax"],
    ["companyScoreMin", "companyScoreMax"],
  ]);
  const q = encodeSearchQuery(newQ);

  return await apiFetch(`/companies/following?${q}`, { signal });
}

async function getFollowedPeople(
  collectionId: number | null | undefined,
  order: string,
  orderBy: string,
  page: number,
  perPage: number,
  signal: AbortSignal
): Promise<ApiMetaData<PeopleSearchResult[], PaginationData>> {
  const q = queryString.stringify({
    collection_id: collectionId,
    order,
    order_by: orderBy,
    page,
    per_page: perPage,
  });
  return await apiFetch(`/people/following?${q}`, { signal });
}

async function getLatestFilings(id: number, limit: number = 3) {
  return await apiFetch(`/spacs/latest_filings?id=${id}&limit=${limit}`);
}

async function getLatestCompanyPrfs(query: Partial<LatestCompanyPrfQuery>) {
  const snakeQ: any = _.chain(query)
    .pickBy((val) => !!val)
    .mapKeys((val, key) => _.snakeCase(key))
    .value();

  snakeQ["flags[]"] = _.flatMap([snakeQ.flags]);
  snakeQ["sectors[]"] = _.flatMap([snakeQ.sectors]);
  snakeQ["sizes[]"] = _.flatMap([snakeQ.sizes]);
  delete snakeQ.flags;
  delete snakeQ.sectors;
  delete snakeQ.sizes;

  return await apiFetch(`/tools/latest_prfs?${queryString.stringify(snakeQ)}`);
}

async function getMarketSummaries(
  market_group: number,
  interval: TimeInterval
): Promise<ApiData<MarketSummary[]>> {
  const q = queryString.stringify({ ...interval, market_group });
  return await apiFetch(`/summaries/market?${q}`);
}

async function getPerformanceHistograms(
  interval: TimeInterval
): Promise<ApiData<MarketPerformanceHistogramFrame>> {
  const q = queryString.stringify(interval);
  return await apiFetch(`/performance-histogram?${q}`);
}

async function getPersonRatings(id: string): Promise<ApiData<PersonRating[]>> {
  return await apiFetch(`/people/${id}/reviews`);
}

async function getPfpAverages(
  id: string,
  signal: AbortSignal
): Promise<PfpAverage[]> {
  const res = await apiFetch(`/people/${id}/pfp_averages`, { signal });
  return res.data;
}

async function getPfpCompanies(
  id: string,
  signal: AbortSignal
): Promise<PfpCompany[]> {
  const res = await apiFetch(`/people/${id}/pfp_companies`, { signal });
  return res.data;
}

async function getRedemptionMonitor() {
  return await apiFetch("/spacs/redempt_monitor");
}

async function getSICPerformanceHistograms(
  sic_group: string,
  interval: TimeInterval
): Promise<ApiData<PerformanceHistogramFrame>> {
  const q = queryString.stringify({ ...interval, sic_group });
  return await apiFetch(`/sic-performance-histogram?${q}`);
}

async function getIndustryPeerTransactions(id: string) {
  return await apiFetch(`/companies/${id}/peer_transactions`);
}

async function getIndustrySummaries(
  sicGroup: string,
  interval: TimeInterval
): Promise<ApiData<MarketSummary[]>> {
  const q = queryString.stringify({
    ...interval,
    sic_group: sicGroup,
  });

  return await apiFetch(`/summaries/sic?${q}`);
}

async function getInsiderAnalytics(
  id: string | number | undefined,
  signal?: AbortSignal
) {
  return await apiFetch(`/companies/${id}/insider_trading_analysis`, {
    signal,
  });
}

async function getInsiderPlanMonitor(query: InsiderPlanQuery) {
  const snakeQ: any = _.chain(query)
    .pickBy((v) => !!v)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();

  snakeQ["industries[]"] = _.flatMap([snakeQ.industries]);
  snakeQ["sectors[]"] = _.flatMap([snakeQ.sectors]);
  snakeQ["sizes[]"] = _.flatMap([snakeQ.sizes]);

  delete snakeQ.industries;
  delete snakeQ.sectors;
  delete snakeQ.sizes;
  return await apiFetch(
    `/tools/insider_plan_monitor?${queryString.stringify(snakeQ)}`
  );
}

async function getInvestorActivities(
  id: number,
  category: InvestorActivityCategory
): Promise<ApiData<InvestorActivity[]>> {
  return await apiFetch(
    `/companies/${id}/investor_activity?category=${category}`
  );
}

async function getInvestorActivityMeta(
  id: number
): Promise<ApiData<InvestorActivityMeta | null>> {
  return await apiFetch(`/companies/${id}/investor_activity_meta`);
}

async function getInvestorActivitySummary(
  id: number
): Promise<ApiData<InvestorActivitySummary>> {
  return await apiFetch(`/companies/${id}/investor_activity_summary`);
}

async function getInvitation(search: string) {
  return await apiFetch(`/invitations/find_by_token${search}`);
}

async function getIpoExperts(
  category: "auditor" | "legal" | "underwriters",
  query: IpoExpertsQuery,
  signal: AbortSignal
) {
  const snakeQ: any = _.chain(query)
    .omitBy(_.isNil)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();

  snakeQ["statuses[]"] = _.flatMap([snakeQ.statuses]);
  snakeQ["years[]"] = _.flatMap([snakeQ.years]);
  delete snakeQ.statuses;
  delete snakeQ.years;

  return await apiFetch(
    `/tools/ipo_experts?category=${category}&${queryString.stringify(snakeQ)}`,
    { signal }
  );
}

async function getIpoOptions(status: string) {
  return await apiFetch(`/options/ipo_options?status=${status}`);
}

async function getIpoPriced(query: IpoMonitorQuery, signal: AbortSignal) {
  const snakeQ: any = _.chain(query)
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  snakeQ["industries[]"] = _.flatMap([snakeQ.industries]);
  delete snakeQ.industries;
  return await apiFetch(`/tools/ipos_priced?${queryString.stringify(snakeQ)}`, {
    signal,
  });
}

async function getPersonSummary(
  personCik: string,
  companyId: number | string | undefined
) {
  const snakeQ: Object = _.chain({ tickerOrCik: companyId })
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  return await apiFetch(
    `/people/${personCik}/summary?${queryString.stringify(snakeQ)}`
  );
}

async function getInsiderTrading(
  params: any,
  isCompany: any,
  signal?: AbortSignal
) {
  if (!!params.category) {
    let path = `/tools/tracker_data?date_range=${params.date_range}&view_type=`;
    // isCompany (company name/ticker search)
    path += isCompany
      ? `${params.category}&term=${params.value}`
      : `group_by&${params.category}=${params.value}`;
    return await apiFetch(path, { signal });
  } else {
    // default search: Large Market Cap Year to date
    return await apiFetch(
      `/tools/tracker_data?date_range=Year%20to%20date&view_type=group_by&marketcap=5`,
      { signal }
    );
  }
}

async function getRawInsiderActivities(
  id: string,
  year: string,
  isCompany: boolean
) {
  return await apiFetch(
    `/${isCompany ? "companies" : "people"
    }/${id}/raw_insider_activities?year=${year}`
  );
}

async function getResearch(type: "full" | "excerpt", limit: number = 10) {
  return await apiFetch(`/rss/research?limit=${limit}&type=${type}`);
}

async function getRiskFactor10KAnalysis(
  cik: number,
  query: RiskFactor10KAnalysisQuery
): Promise<ApiData<RiskFactor10KAnalysis[]>> {
  return await apiFetch(
    `/companies/${cik}/risk_factor_10k_analytic?${queryString.stringify(query)}`
  );
}

async function getRiskFactor10KContextIntervals(
  cik: number,
  period: string
): Promise<ApiData<number[]>> {
  return await apiFetch(
    `/options/risk_factor_10k_context_intervals?company_cik=${cik}&period=${period}`
  );
}

async function getRiskFactor10KEdgarPeriods(
  cik: number
): Promise<ApiData<Array<{ label: string; value: string }>>> {
  return await apiFetch(
    `/options/risk_factor_10k_edgar_periods?company_cik=${cik}`
  );
}

async function getSayOnPay(
  query: SayOnPayQuery,
  ticker: string | undefined,
  signal: AbortSignal
): Promise<ApiMetaData<SayOnPay[], PaginationData>> {
  const q = encodeQuery(query);
  let path = "/tools/say_on_pay";
  if (!!ticker) path += `/${ticker}`;
  return await apiFetch(`${path}?${q}`, { signal });
}

async function getSayOnPaySummary(
  query: Partial<SayOnPayQuery>
): Promise<
  ApiData<{ prev: null | SayOnPaySummary; ytd: null | SayOnPaySummary }>
> {
  return await apiFetch(
    `/tools/say_on_pay_summary?${queryString.stringify(query)}`
  );
}

async function getInsiderCluster(cik: number | string, id: number) {
  return await apiFetch(`/signals/${id}/ia_cluster_hover?cik=${cik}`);
}

async function getSourceSignal(
  id: number | string,
  query: Object,
  source: "company" | "person",
  type: "gov_and_perf" | "insider",
  perPage: number = 7
) {
  const isInsider = type === "insider";
  const snakeQ = _.chain(query)
    .pickBy((value) => !!value)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  const q = queryString.stringify(_.merge(snakeQ, { id, source }));
  return await apiFetch(
    `/signals/${isInsider
      ? "insider_signals"
      : `${source === "company" ? "company_prfs" : "lists"}`
    }?${q}&per_page=${perPage}`
  );
}

async function getSpacInsiderActivity() {
  return await apiFetch("/spacs/insider_activity");
}

async function getTerminatedDeals(signal: AbortSignal) {
  return await apiFetch("/spacs/terminated_deals", { signal });
}

async function getTheStreet(type: "full" | "excerpt", limit: number = 10) {
  return await apiFetch(`/rss/the_street?limit=${limit}&type=${type}`);
}

async function getTopBottomDirectors(
  by: string,
  query: PeopleSearchQuery
): Promise<{
  data: {
    top: CompanyDirector[];
    bottom: CompanyDirector[];
  };
}> {
  const q = encodeSearchQuery(query);
  return await apiFetch(`/people?${q}&by=${by}`);
}

async function getTopInvestors(date: string, signal: AbortSignal) {
  return await apiFetch(`/spacs/top_investors?date=${date}`, { signal });
}

async function getTwitterDetail(id: string): Promise<ApiData<TwitterDetail>> {
  return await apiFetch(`/social_media/twitter_detail?account_id=${id}`);
}

async function getCollections(): Promise<ApiData<Collection[]>> {
  return await apiFetch("/collections");
}

async function getEntityCollections(
  id: number | string,
  source: "companies" | "people"
) {
  return await apiFetch(`/${source}/${id}/collections`);
}

async function getExpert(
  category: "auditor" | "legal" | "xfer_agent",
  id: string
) {
  return await apiFetch(
    `/experts/${id}?${queryString.stringify({ category })}`
  );
}

async function getExpertCompanies(
  query: ExpertQuery,
  canadian: boolean = false
): Promise<ApiData<ExpertCompany[]>> {
  const omitted = _.omitBy(query, _.isEmpty);
  omitted["flags[]"] = _.flatMap([omitted.flags]);
  omitted["sizes[]"] = _.flatMap([omitted.sizes]);
  omitted["years[]"] = _.flatMap([omitted.years]);
  delete omitted.flags;
  delete omitted.sizes;
  delete omitted.years;
  const path: string = canadian
    ? "/region_ca/expert_companies_served"
    : "/experts/companies_served";
  return await apiFetch(`${path}?${queryString.stringify(omitted)}`);
}

async function getExpertCompaniesBy(
  query: Partial<ExpertQuery>
): Promise<ExpertCompanyAvg[]> {
  return await apiFetch(
    `/experts/companies_served_by?${queryString.stringify(query)}`
  );
}

async function getExpertContacts(
  query: ExpertQuery,
  canadian: boolean = false
): Promise<Array<{ email: string }>> {
  const path: string = canadian
    ? "/region_ca/expert_contacts"
    : "/experts/contacts";
  return await apiFetch(
    `${path}?${queryString.stringify(_.pick(query, ["category", "id"]))}`
  );
}

async function getExpertIpo(
  category: "auditor" | "legal" | "xfer_agent",
  id: number,
  table: "auditor" | "issuer" | "underwriter" | "xfer_agent"
) {
  return await apiFetch(
    `/experts/${id}/ipo?${queryString.stringify({ category, table })}`
  );
}

async function getExpertOptions(
  category: ExpertCategory,
  canadian: boolean = false
): Promise<Array<{ label: string; value: string }>> {
  const route: string = canadian ? "ca_expert_names" : "expert_names";
  return await apiFetch(
    `/options/${route}?${queryString.stringify({ category })}`
  );
}

async function getExpertSummary(
  category: "auditor" | "legal" | "xfer_agent",
  id: number
) {
  return await apiFetch(
    `/experts/${id}/summary?${queryString.stringify({ category })}`
  );
}

async function getExperts(signal: AbortSignal) {
  return await apiFetch("/companies/experts", { signal });
}

async function postComment(query: CommentQuery) {
  return await apiFetch("/comments", {
    body: JSON.stringify(query),
    method: "post",
  });
}

async function postCompaniesUserPeerGroup(
  cik: number | string,
  id: number
): Promise<{
  data: Partial<CompanySearchResult> | null;
  success: boolean;
}> {
  return await apiFetch(`/user_peer_groups/${id}/add_company`, {
    body: JSON.stringify({ cik }),
    method: "post",
  });
}

async function deleteUploadLabel(
  id: number,
  labelId: number
): Promise<{ meta: { success: boolean } }> {
  return await apiFetch(`/uploads/${id}/label`, {
    body: JSON.stringify({ label_id: labelId }),
    method: "delete",
  });
}

async function postUploadLabel(
  id: number,
  name: string
): Promise<{
  data: { id: number; label: string; value: string };
  meta: { success: boolean };
}> {
  return await apiFetch(`/uploads/${id}/label`, {
    body: JSON.stringify({ name }),
    method: "post",
  });
}

async function deleteUpload(
  id: number
): Promise<{ meta: { success: boolean } }> {
  return await apiFetch(`/uploads/${id}`, { method: "delete" });
}

async function postUpload(
  query: Object
): Promise<{ data: ActiveStorageBlob | null; meta: { success: boolean } }> {
  const snakeQ: Object = _.chain(query)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  return await apiFetch("/uploads", {
    body: JSON.stringify(snakeQ),
    method: "post",
  });
}

async function putUpload(
  query: ActiveStorageBlobQuery
): Promise<{ data: ActiveStorageBlob; meta: { success: boolean } }> {
  const snakeQ = _.chain(query)
    .omit("id")
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  return await apiFetch(`/uploads/${query.id}`, {
    body: JSON.stringify(snakeQ),
    method: "put",
  });
}

async function putDefaultPeerGroup(
  cik: number,
  peer_type: string,
  peer_id: string,
): Promise<{ success: boolean }> {
  return await apiFetch(`/companies/${cik}/set_default_peer_group`, {
    body: JSON.stringify({ peer_group_id: peer_id, peer_type: peer_type }),
    method: "put",
  });
}

async function deleteCompaniesUserPeerGroup(
  cik: number,
  id: number
): Promise<{ success: boolean }> {
  return await apiFetch(`/user_peer_groups/${id}/remove_company`, {
    body: JSON.stringify({ cik }),
    method: "delete",
  });
}

async function getCompanyUserPeerGroups(
  id: number
): Promise<{ data: number[] }> {
  return await apiFetch(`/companies/${id}/user_peer_groups`);
}

async function postUserFilingAlert(input: {
  [key: string]: boolean | number | number[] | string;
}) {
  return await apiFetch(`/user_filing_alerts?${queryString.stringify(input)}`, {
    method: "post",
  });
}

async function putUserFilingAlert(input: {
  [key: string]: boolean | number | number[] | string;
}) {
  return await apiFetch(
    `/user_filing_alerts/${input.id}?${queryString.stringify(input)}`,
    { method: "put" }
  );
}

async function deleteUserFilingAlert(id: number) {
  return await apiFetch(`/user_filing_alerts/${id}`, { method: "delete" });
}

async function getUserBlobs(
  query: UserBlobsQuery
): Promise<ApiData<Array<ActiveStorageBlob>>> {
  const snakeQ: any = _.chain(query)
    .omitBy(_.isEmpty)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();
  snakeQ["labels[]"] = _.flatMap([snakeQ.labels]);
  delete snakeQ.labels;
  return await apiFetch(`/user/file_uploads?${queryString.stringify(snakeQ)}`);
}

async function getUserFilingAlerts(): Promise<ApiData<Array<UserFilingAlert>>> {
  return await apiFetch("/user_filing_alerts/subscriptions");
}

async function getUserPeerGroupCompanies(
  id: number,
  query: Partial<UserPeerGroupQuery>
): Promise<{
  data: Partial<CompanySearchResult>[];
  meta: PaginationData;
}> {
  const baseQuery: Partial<UserPeerGroupQuery> = {
    order: "asc",
    orderBy: "company_name",
    page: 1,
    perPage: 50,
  };
  const snakeQ: Object = _.mapKeys({ ...baseQuery, ...query }, (value, key) =>
    _.snakeCase(key)
  );
  return await apiFetch(
    `/user_peer_groups/${id}/companies?${queryString.stringify(snakeQ)}`
  );
}

async function getUserPeerGroups(): Promise<{
  user_peer_groups: UserPeerGroup[];
}> {
  return await apiFetch("/user/peer_groups");
}

async function postUserPeerGroup(
  name: string
): Promise<{ data?: UserPeerGroup; success: boolean }> {
  return await apiFetch("/user_peer_groups", {
    body: JSON.stringify({ name }),
    method: "post",
  });
}

async function putUserPeerGroup(
  id: number,
  name: string
): Promise<{ data?: UserPeerGroup; success: boolean }> {
  return await apiFetch(`/user_peer_groups/${id}`, {
    body: JSON.stringify({ name }),
    method: "put",
  });
}

async function deleteUserPeerGroup(
  id: number
): Promise<{ data?: UserPeerGroup; success: boolean }> {
  return await apiFetch(`/user_peer_groups/${id}`, { method: "delete" });
}

async function getExecMoves(query: ExecLeaverQuery) {
  let snakeQ: any = _.chain(query)
    .pickBy((v) => !!v)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();

  snakeQ["industries[]"] = _.flatMap([snakeQ.industries]);
  snakeQ["roles[]"] = _.flatMap([snakeQ.roles]);
  snakeQ["sectors[]"] = _.flatMap([snakeQ.sectors]);
  snakeQ["sizes[]"] = _.flatMap([snakeQ.sizes]);

  delete snakeQ.roles;
  delete snakeQ.industries;
  delete snakeQ.sectors;
  delete snakeQ.sizes;

  return await apiFetch(`/tools/exec_moves?${queryString.stringify(snakeQ)}`);
}

async function getExpertsMonitor(
  isLegalCounsel: boolean,
  year: number,
  signal: AbortSignal
): Promise<{ data: Expert[] }> {
  return await apiFetch(
    `/spacs/experts_monitor?is_legal_counsel=${isLegalCounsel}&year=${year}`,
    { signal }
  );
}

async function getWordpressPost(id: string) {
  return await apiFetch(`/rss/wordpress/${id}`);
}

async function postCollection(name: string) {
  return await apiFetch("/collections", {
    body: JSON.stringify({ name }),
    method: "post",
  });
}

async function postFollowsCollection(collectionId: number, followId: number) {
  return await apiFetch("/follows_collections", {
    body: JSON.stringify({
      collection_id: collectionId,
      follow_id: followId,
    }),
    method: "post",
  });
}

async function putCollection(id: number, name: string) {
  return await apiFetch("/collections", {
    body: JSON.stringify({ id, name }),
    method: "put",
  });
}

async function putComment(query: CommentQuery) {
  return await apiFetch(`/comments/${query.id}`, {
    body: JSON.stringify(query),
    method: "put",
  });
}

async function deleteCollection(id: number) {
  return await apiFetch("/collections", {
    body: JSON.stringify({ id }),
    method: "delete",
  });
}

async function deleteComment(id: number) {
  return await apiFetch(`/comments/${id}`, {
    method: "delete",
  });
}

async function deleteFollowsCollection(
  collectionId: number | null | undefined,
  followId: number
) {
  return await apiFetch("/follows_collections", {
    body: JSON.stringify({
      collection_id: collectionId,
      follow_id: followId,
    }),
    method: "delete",
  });
}

async function getUserPayload(): Promise<User> {
  const r = await apiFetch(`/user/payload`);
  return r.user;
}

async function postPersonRating(
  id: string,
  for_role: Role,
  rating: number,
  review: string
): Promise<any> {
  return await apiFetch(`/people/${id}/create_review`, {
    body: JSON.stringify({ review: { for_role, rating, review } }),
    method: "post",
  });
}

async function passwordReq(signal: AbortSignal) {
  return await apiFetch(`/sessions/password_requirements`, { signal });
}

async function postMagicLink(email: string, path: String) {
  return await apiFetch("/magic_links", {
    body: JSON.stringify({ email, path }),
    method: "post",
  });
}

async function postSupportQuery(email: string, query: string): Promise<any> {
  return await apiFetch("/support_query", {
    body: JSON.stringify({ email, query }),
    method: "post",
  });
}

async function searchCompanyData(
  query: Object
): Promise<ApiData<CompanyDataResult[]>> {
  const snakeQ: string = encodeQuery(query);
  return await apiFetch(`/search/company_data?${snakeQ}`);
}

async function searchCompanyNames(
  query: string
): Promise<ApiData<SearchResult[]>> {
  return await apiFetch(`/search/company_names?q=${query}`);
}

async function searchCopilotConfigsQuestion(query: {
  formType: string;
  keyword: string;
}): Promise<ApiData<string[]>> {
  return await apiFetch(
    `/search/copilot_configs_question?${encodeQuery(query)}`
  );
}

async function searchFormNpxOwner(
  query: Object,
): Promise<ApiData<FormNpxOwner[]>> {
  const snakeQ: string = encodeQuery(query);
  return await apiFetch(
    `/search/form_npx_owner?${snakeQ}`
  );
}

async function searchPeopleNames(query: string) {
  return await apiFetch(`/search/people_names?q=${query}`);
}

async function searchNames(query: string): Promise<ApiData<SearchResult[]>> {
  return await apiFetch(`/search/names?q=${query}`);
}

async function search(
  type: "people" | "companies",
  page: number,
  param: "name" | "sic",
  term: string,
  alphaMin: number,
  alphaMax: number
): Promise<any> {
  const q = queryString.stringify({
    [param]: term,
    alpha_max: alphaMax,
    alpha_min: alphaMin,
    page,
  });

  return await apiFetch(`/search/${type}?${q}`);
}

async function searchCompanies(
  query: CompaniesSearchQuery,
  signal: AbortSignal
): Promise<ApiMetaData<CompanySearchResult[], PaginationData>> {
  const newQ: Partial<CompaniesSearchQuery> = {
    cashEquivalentsMax: query.cashEquivalentsMax,
    cashEquivalentsMin: query.cashEquivalentsMin,
    cashExpenditureMax: query.cashExpenditureMax,
    cashExpenditureMin: query.cashExpenditureMin,
    cashFromOperationsMax: query.cashFromOperationsMax,
    cashFromOperationsMin: query.cashFromOperationsMin,
    ceoGender: query.ceoGender,
    cfoAgeMax: query.cfoAgeMax,
    cfoAgeMin: query.cfoAgeMin,
    cfoTenureMax: query.cfoTenureMax,
    cfoTenureMin: query.cfoTenureMin,
    city: query.city,
    companySize: query.companySize,
    dilutedEpsMax: query.dilutedEpsMax,
    dilutedEpsMin: query.dilutedEpsMin,
    ebitdaMax: query.ebitdaMax,
    ebitdaMin: query.ebitdaMin,
    flag: query.flag,
    grossProfitMax: query.grossProfitMax,
    grossProfitMin: query.grossProfitMin,
    industry: query.industry,
    isActive: query.isActive,
    isDeSpac: query.isDeSpac,
    netIncomeMax: query.netIncomeMax,
    netIncomeMin: query.netIncomeMin,
    order: query.order,
    orderBy: query.orderBy,
    page: query.page,
    perPage: Math.min(query.perPage),
    priceEarningsMax: query.priceEarningsMax,
    priceEarningsMin: query.priceEarningsMin,
    sector: query.sector,
    state: query.state,
    stateIncorp: query.stateIncorp,
    totalDebtMax: query.totalDebtMax,
    totalDebtMin: query.totalDebtMin,
    totalRevenueMax: query.totalRevenueMax,
    totalRevenueMin: query.totalRevenueMin,
    tsrInterval: query.tsrInterval,
    tsrMax: query.tsrMax,
    tsrMin: query.tsrMin,
    zip: query.zip,
    zScoreMin: query.zScoreMin,
    zScoreMax: query.zScoreMax,
  };
  mergeQueryRangesV2(query, newQ, [
    ["boardAvgAgeMin", "boardAvgAgeMax", 20, 100],
    ["boardAvgTenureMin", "boardAvgTenureMax", 0, 60],
    ["boardPctFemaleMin", "boardPctFemaleMax", 0, 100],
    ["ceoAgeMin", "ceoAgeMax", 0, 91],
    ["ceoTenureMin", "ceoTenureMax", 0, 51],
    ["ceoRatingMin", "ceoRatingMax"],
    ["cfoRatingMin", "cfoRatingMax"],
    ["companyScoreMin", "companyScoreMax"],
    ["esgOverallMin", "esgOverallMax"],
    ["esgEnvironmentalMin", "esgEnvironmentalMax"],
    ["esgSocialMin", "esgSocialMax"],
    ["esgGovernanceMin", "esgGovernanceMax"],
    ["totalRevenueMin", "totalRevenueMax"],
    ["grossProfitMin", "grossProfitMax"],
    ["ebitdaMin", "ebitdaMax"],
    ["netIncomeMin", "netIncomeMax"],
    ["dilutedEpsMin", "dilutedEpsMax"],
    ["priceEarningsMin", "priceEarningsMax"],
    ["cashEquivalentsMin", "cashEquivalentsMax"],
    ["totalDebtMin", "totalDebtMax"],
    ["cashFromOperationsMin", "cashFromOperationsMax"],
    ["cashExpenditureMin", "cashExpenditureMax"],
    ["zScoreMin", "zScoreMax"],
  ]);
  const q = encodeSearchQuery(newQ);
  return await apiFetch(`/search/companies?${q}`, { signal });
}

async function searchPeople(
  query: PeopleSearchQuery,
  signal: AbortSignal,
  source: string = "advanced"
): Promise<ApiMetaData<PeopleSearchResult[], PaginationData>> {
  const newQ: Partial<PeopleSearchQuery> = {
    bioSearch: query.bioSearch,
    committee: query.committee,
    companySize: query.companySize,
    currentOnly: query.currentOnly,
    gender: query.gender,
    ids: query.ids,
    industry: query.industry,
    isCurrent: query.isCurrent,
    order: query.order,
    orderBy: query.orderBy,
    page: query.page,
    perPage: query.overridePerPageLimit
      ? query.perPage
      : Math.min(query.perPage, 50),
    race: query.race,
    role: query.role,
    tsrMax: query.tsrMax,
    tsrMin: query.tsrMin,
    sector: query.sector,
  };
  mergeQueryRangesV2(query, newQ, [
    ["ageMin", "ageMax", 20, 91],
    ["alphaMin", "alphaMax"],
    ["companiesMin", "companiesMax", 0, 11],
    ["esgOverallMin", "esgOverallMax"],
    ["esgEnvironmentalMin", "esgEnvironmentalMax"],
    ["esgGovernanceMin", "esgGovernanceMax"],
    ["esgSocialMin", "esgSocialMax"],
    ["yearsMin", "yearsMax", 0, 41],
  ]);
  const q = encodePeopleSearchQuery(newQ);
  return await apiFetch(`/search/${source}?${q}`, { signal });
}

async function searchSharadarTickers(
  query: Object
): Promise<ApiData<SharadarTickerResult[]>> {
  const snakeQ: string = encodeQuery(query);
  return await apiFetch(`/search/sharadar_tickers?${snakeQ}`);
}

async function signIn(email: string, password: string): Promise<any> {
  return await apiFetch("/sessions", {
    body: JSON.stringify({ email, password }),
    method: "post",
  });
}

async function signInViaLink(id: string) {
  return await apiFetch("/sessions/create_via_link", {
    body: JSON.stringify({ id }),
    method: "post",
  });
}

async function signUp(
  email: string,
  password: string,
  password2: string,
  account_code?: string
): Promise<any> {
  return await apiFetch("/sessions/create_user_and_session", {
    body: JSON.stringify({ email, password, password2, account_code }),
    method: "post",
  });
}

async function updatePwToken(
  token: string,
  password: string,
  password2: string
): Promise<any> {
  return await apiFetch("/sessions/update_password_token", {
    body: JSON.stringify({ token, password, password2 }),
    method: "post",
  });
}

async function updatePw(
  currentPassword: string,
  password: string,
  password2: string
) {
  return await apiFetch("/user/update_password", {
    body: JSON.stringify({
      current_password: currentPassword,
      password,
      password2,
    }),
    method: "put",
  });
}

async function updateLandingPage(landingPage: string) {
  return await apiFetch("/user/update_landing_page", {
    body: JSON.stringify({ landing_page: landingPage }),
    method: "put",
  });
}

async function updateUserSubscription(
  subscription: keyof UserSubscriptionAlert
) {
  return await apiFetch("/user/update_subscription", {
    body: JSON.stringify({ subscription }),
    method: "put",
  });
}

async function voteReviewDown(id: number) {
  return await apiFetch(`/reviews/${id}/thumbs_down`, { method: "put" });
}

async function voteReviewUp(id: number) {
  return await apiFetch(`/reviews/${id}/thumbs_up`, { method: "put" });
}

async function exportFile(
  collectionId: number | null | undefined,
  fileType: string,
  path: string,
  query: any
) {
  let q: string | null = null;
  if (!!query) {
    if (path === "followed_companies" || path === "search_companies") {
      const newQ: Partial<CompaniesSearchQuery> = {
        boardAvgAgeMax: query.boardAvgAgeMax,
        boardAvgAgeMin: query.boardAvgAgeMin,
        boardAvgTenureMax: query.boardAvgTenureMax,
        boardAvgTenureMin: query.boardAvgTenureMin,
        boardPctFemaleMax: query.boardPctFemaleMax,
        boardPctFemaleMin: query.boardPctFemaleMin,
        ceoGender: query.ceoGender,
        city: query.city,
        collectionId,
        companySize: query.companySize,
        flag: query.flag,
        industry: query.industry,
        isActive: query.isActive,
        isDeSpac: query.isDeSpac,
        order: query.order,
        orderBy: query.orderBy,
        page: query.page,
        perPage: Math.min(query.perPage, 300),
        sector: query.sector,
        state: query.state,
        stateIncorp: query.stateIncorp,
        tsrInterval: query.tsrInterval,
        zip: query.zip,
      };
      mergeQueryRangesV2(query, newQ, [
        ["ceoAgeMin", "ceoAgeMax", 0, 91],
        ["ceoTenureMin", "ceoTenureMax", 0, 51],
        ["ceoRatingMin", "ceoRatingMax"],
        ["cfoRatingMin", "cfoRatingMax"],
        ["companyScoreMin", "companyScoreMax"],
        ["esgEnvironmentalMin", "esgEnvironmentalMax"],
        ["esgGovernanceMin", "esgGovernanceMax"],
        ["esgOverallMin", "esgOverallMax"],
        ["esgSocialMin", "esgSocialMax"],
        ["tsrMax", "tsrMin"],
      ]);
      q = encodeSearchQuery(newQ);
    } else if (path === "followed_people" || path === "search_people") {
      const newQ: Partial<PeopleSearchQuery> = {
        bioSearch: query.bioSearch,
        collectionId,
        committee: query.committee,
        companySize: query.companySize,
        currentOnly: query.currentOnly,
        gender: query.gender,
        ids: query.ids,
        industry: query.industry,
        isCurrent: query.isCurrent,
        order: query.order,
        orderBy: query.orderBy,
        page: query.page,
        perPage: query.overridePerPageLimit
          ? query.perPage
          : Math.min(query.perPage, 50),
        race: query.race,
        role: query.role,
        tsrMax: query.tsrMax,
        tsrMin: query.tsrMin,
        sector: query.sector,
      };
      mergeQueryRangesV2(query, newQ, [
        ["ageMin", "ageMax", 20, 90],
        ["alphaMin", "alphaMax"],
        ["companiesMin", "companiesMax", 0, 10],
        ["esgOverallMin", "esgOverallMax"],
        ["esgEnvironmentalMin", "esgEnvironmentalMax"],
        ["esgGovernanceMin", "esgGovernanceMax"],
        ["esgSocialMin", "esgSocialMax"],
        ["yearsMin", "yearsMax", 0, 20],
      ]);
      q = encodeSearchQuery(newQ);
    } else if (path === "spac_analytics") {
      q = encodeSpacQuery(query);
    } else {
      q = encodeQuery(query);
    }
  }
  const exportPath = `/export/${path}.${fileType}`;
  const res = await fetch(
    baseURL() + (!!q ? `${exportPath}?${q}` : exportPath),
    {
      credentials: "include",
      headers: {
        ...authHeader(),
        Accept: "application/octet-stream",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/octet-stream",
        "X-Forwarded-Proto": window.location.port === "3001" ? "http" : "https",
      },
    }
  );
  if (res.status === 401) {
    delete localStorage.authToken;
    window.location.assign("/");
  }
  if (res.status === 500) return;

  const blob = await res.blob();
  return blob;
}
export default {
  authCheck,
  claimProfile,
  deleteCollection,
  deleteComment,
  deleteCompaniesUserPeerGroup,
  deleteCopilotConfigsQuestion,
  deleteFollowsCollection,
  deleteUpload,
  deleteUploadLabel,
  deleteUserFilingAlert,
  deleteUserPeerGroup,
  exportFile,
  followCompany,
  followPerson,
  forgotPwEmail,
  getSignupAccountDetails,
  getActivismTracker,
  getActivistList,
  getAppOptions,
  getLandingPageOptions,
  getArticle,
  getBoardRefreshment,
  getCompanyByUid,
  getActivist13F,
  getActivist13FMeta,
  getActivistAffiliates,
  getActivistInvestorFilings,
  getActivistLatestFilings,
  getActivistVulnerabilityMonitor,
  getBAFeed,
  getPodcast,
  getCeoPayComparison,
  getCollections,
  getCommentChildren,
  getComments,
  getCompany,
  getCompanyActivistRisk,
  getCompanyDefaultPeerGroup,
  getCompanyEndOfWeekStockPrices,
  getCompanyEsgHistory,
  getCompanyEsgPerformance,
  getCompanyFinancials,
  getCompanyInstitutionalHoldingHistory,
  getCompanyInstitutionalHoldings,
  getCompanyInsuranceMeta,
  getCompanyInterlock,
  getCompanyInvestorFiling,
  getCompanyIpos,
  getCompanyMarketSnapshot,
  getCompanyMeta,
  getCompanyMentions,
  getCompanyPcaobAuditorHistory,
  getCompanyProviders,
  getRawInsiderActivities,
  getCompanyStockPrices,
  getCompanyNpx,
  getCompanyUserPeerGroups,
  getComparisonReport,
  getContacts,
  getContentPage,
  getCopilotCannedQuestions,
  getCopilotCompanyFiling,
  getCopilotFileUpload,
  getCopilotFilingIsScraped,
  getCopilotFormTypeOptions,
  getCopilotUserQuestions,
  getDealScreener,
  getDensityPlots,
  getDiversityGraph,
  getDiversityTable,
  getEntityCollections,
  getEsgSignals,
  getExecMoves,
  getExpert,
  getExpertCompanies,
  getExpertCompaniesBy,
  getExpertContacts,
  getExpertIpo,
  getExpertOptions,
  getExpertSummary,
  getExperts,
  getExpertsMonitor,
  getFileUploadLabelOptions,
  getFileUploadOptions,
  getFilingAlertFormTypes,
  getFilingDiff,
  getFilingSourceURL,
  getFilings,
  getFollowedCompanies,
  getFollowedPeople,
  getFundStockPrices,
  getGovernance,
  getHeatMap,
  getIndustryPeerTransactions,
  getIndustrySummaries,
  getInsiderAnalytics,
  getInsiderCluster,
  getInsiderPlanMonitor,
  getInsiderTrading,
  getInvestorActivities,
  getInvestorActivityMeta,
  getInvestorActivitySummary,
  getInvitation,
  getIpoExperts,
  getIpoOptions,
  getIpoPriced,
  getLatestFilings,
  getLatestCompanyPrfs,
  getMarketSummaries,
  getNotifications,
  getNpxDetail,
  getOwnerNpxSummary,
  getOwnerNpxDirectorNoVotes,
  getOwnerNpxSayOnPayNoVotes,
  getPcaobAuditorCompaniesServed,
  getPcaobAuditorCompaniesServedBy,
  getPcaobAuditorCountryOptions,
  getPcaobAuditorFirmOptions,
  getPcaobAuditorPartnerOptions,
  getPcaobAuditorYearOptions,
  getPeerDirectorAvg,
  getPeersPerformance,
  getPerformance,
  getPerformanceHistograms,
  getPerformanceReportOptions,
  getPerson,
  getPersonAssociatedOrg,
  getPersonEsgPerformance,
  getPersonInsiderPerformance,
  getPersonMentions,
  getPersonMentions502,
  getPersonMeta,
  getPersonNetwork,
  getPersonRatings,
  getPersonTenures,
  getPersonTsrSummary,
  getPfpAverages,
  getPfpCompanies,
  getPrivateCompanyFunding,
  getPrivateCompanyFundingMeta,
  getRedemptionMonitor,
  getResearch,
  getRiskFactor10KAnalysis,
  getRiskFactor10KContextIntervals,
  getRiskFactor10KEdgarPeriods,
  getSayOnPay,
  getSayOnPaySummary,
  getSedarFilings,
  getSedarFormGroupOptions,
  getSICPerformanceHistograms,
  getSocialMedia,
  getSourceSignal,
  getSpacCalendar,
  getSpacDomiciles,
  getSpacInsiderActivity,
  getSpacList,
  getSpacMarketSummary,
  getSpacMetricYearOptions,
  getSpacOptions,
  getSpacSponsor,
  getSpacUnderwriters,
  getSpacWarrantSummary,
  getTerminatedDeals,
  getTheStreet,
  getTopBottomDirectors,
  getTopInvestors,
  getTwitterDetail,
  getUserBlobs,
  getUserFilingAlerts,
  getUserPayload,
  getUserPeerGroupCompanies,
  getUserPeerGroups,
  getWordpressPost,
  passwordReq,
  postApiKey,
  postCollection,
  postComment,
  postCompaniesUserPeerGroup,
  postCopilotConfigsQuestion,
  postFollowsCollection,
  postMagicLink,
  postPersonRating,
  getPersonSummary,
  postSupportQuery,
  postUpload,
  postUploadLabel,
  postUserFilingAlert,
  postUserPeerGroup,
  putApiKey,
  putCollection,
  putComment,
  putCopilotConfigsQuestion,
  putDefaultPeerGroup,
  putUpload,
  putUserFilingAlert,
  putUserPeerGroup,
  search,
  searchCompanies,
  searchCompanyData,
  searchCompanyNames,
  searchCopilotConfigsQuestion,
  searchFormNpxOwner,
  searchPeopleNames,
  searchDocs,
  searchNames,
  searchPeople,
  searchSharadarTickers,
  signIn,
  signInViaLink,
  signUp,
  updateLandingPage,
  updatePwToken,
  updatePw,
  updateUserSubscription,
  voteReviewDown,
  voteReviewUp,
};
