import classnames from "classnames";
import src from "../images/loader_puff.svg";

interface Props {
  divClass?: string;
  fullScreen?: boolean;
  imgClass?: string;
  pixels?: string;
}

export default function LoadingIndicator({
  divClass,
  fullScreen,
  imgClass,
  pixels,
}: Props) {
  const dimension: string = fullScreen ? "160px" : pixels || "auto";

  return (
    <div
      className={classnames("flex items-center justify-center", divClass, {
        "h-full": fullScreen,
      })}
    >
      <img
        alt="loading"
        className={imgClass}
        height={dimension}
        src={src}
        width={dimension}
      />
    </div>
  );
}
