import { concat } from "lodash";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../api";
import { Collection, UserPeerGroup } from "../types";

interface State {
  collections: Collection[];
  userPeerGroups: UserPeerGroup[];
}
const initialState: State = {
  collections: [],
  userPeerGroups: [],
};

export const fetchOption = createAsyncThunk("option/fetchOption", async () => {
  const collections = await api.getCollections();
  const res = await api.getUserPeerGroups();
  return {
    collections: collections.data,
    userPeerGroups: res.user_peer_groups,
  };
});

export const createUserPeerGroup = createAsyncThunk(
  "option/createUserPeerGroup",
  async (name: string) => {
    return await api.postUserPeerGroup(name);
  }
);
export const updateUserPeerGroup = createAsyncThunk(
  "option/updateUserPeerGroup",
  async ({ id, name }: { id: number; name: string }) => {
    return await api.putUserPeerGroup(id, name);
  }
);
export const deleteUserPeerGroup = createAsyncThunk(
  "option/deleteUserPeerGroup",
  async (id: number) => {
    return await api.deleteUserPeerGroup(id);
  }
);

export const optionSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    decrementUserPeerGroupCompanies(state, action: PayloadAction<number>) {
      return {
        ...state,
        userPeerGroups: state.userPeerGroups.map((u: UserPeerGroup) =>
          u.id === action.payload ? { ...u, companies: u.companies - 1 } : u
        ),
      };
    },
    incrementUserPeerGroupCompanies(state, action: PayloadAction<number>) {
      return {
        ...state,
        userPeerGroups: state.userPeerGroups.map((u: UserPeerGroup) =>
          u.id === action.payload ? { ...u, companies: u.companies + 1 } : u
        ),
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchOption.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(createUserPeerGroup.fulfilled, (state, action) => {
      if (!action.payload.success) return state;
      return {
        ...state,
        userPeerGroups: concat(
          state.userPeerGroups,
          action.payload.data as UserPeerGroup
        ),
      };
    });
    builder.addCase(updateUserPeerGroup.fulfilled, (state, action) => {
      if (!action.payload.success) return state;
      return {
        ...state,
        userPeerGroups: state.userPeerGroups.map((u: UserPeerGroup) =>
          u.id === (action.payload.data as UserPeerGroup).id
            ? (action.payload.data as UserPeerGroup)
            : u
        ),
      };
    });
    builder.addCase(deleteUserPeerGroup.fulfilled, (state, action) => {
      if (!action.payload.success) return state;
      return {
        ...state,
        userPeerGroups: state.userPeerGroups.filter(
          (u: UserPeerGroup) =>
            u.id !== (action.payload.data as UserPeerGroup).id
        ),
      };
    });
  },
});

export const {
  decrementUserPeerGroupCompanies,
  incrementUserPeerGroupCompanies,
} = optionSlice.actions;
export default optionSlice.reducer;
