import _ from "lodash";
import queryString from "query-string";
import dayjs from "dayjs";
import api from "./api";
import { esgRatings, ratings } from "./dataUtils";
import {
  BADate,
  CompaniesSearchQuery,
  DiversityQuery,
  DocSearchQuery,
  FilingsMonitorQuery,
  PeopleSearchQuery,
  RecurlyQuery,
  SearchQuery,
  SpacQuery,
  User,
  UserRole,
} from "./types";

export function checkRoleAuthorization(
  roles: UserRole[] | undefined,
  permissables: number[]
) {
  if (!roles) return false;
  return roles.some((r: UserRole) => permissables.includes(r.id));
}

export async function downloadFile(
  collectionId: number | null | undefined,
  fileType: string,
  path: string,
  query: any = null
) {
  const blob = await api.exportFile(collectionId, fileType, path, query);
  if (!blob) return;

  const link = document.createElement("a");
  const time = dayjs().format("YYYYMMDD_HHmm");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = `boardroom_alpha_export_${path}${!!query?.page ? `_pg${query.page}` : ""
    }_${time}.${fileType}`;
  document.body.append(link);
  link.dispatchEvent(
    new MouseEvent("click", { bubbles: true, cancelable: true, view: window })
  );
  document.body.removeChild(link);
}

export function encodeDiversitySearchQuery(q: Partial<DiversityQuery>): string {
  const snakeQ: any = _.chain(q)
    .pickBy((v) => !!v)
    .mapKeys((_value, key) => _.snakeCase(key))
    .value();
  snakeQ["industry[]"] = _.flatMap([snakeQ.industry]);
  // snakeQ["race[]"] = _.flatMap([snakeQ.race]);
  snakeQ["role[]"] = _.flatMap([snakeQ.role]);
  snakeQ["sector[]"] = _.flatMap([snakeQ.sector]);
  snakeQ["size[]"] = _.flatMap([snakeQ.size]);

  delete snakeQ.industry;
  // delete snakeQ.race;
  delete snakeQ.role;
  delete snakeQ.sector;
  delete snakeQ.size;

  return queryString.stringify(snakeQ);
}

export function encodeSpacQuery(q: Partial<SpacQuery>) {
  // if timeframes filter is present
  if (!!_.keys(q.timeframes).length) {
    q.timeframes = encodeSpacTimeframes(q.timeframes as Object);
  }

  const snakeQ: any = snakeCase(q);

  snakeQ["auditors[]"] = _.flatMap([snakeQ.auditors]);
  snakeQ["domiciles[]"] = _.flatMap([snakeQ.domiciles]);
  snakeQ["legals[]"] = _.flatMap([snakeQ.legals]);
  snakeQ["stages[]"] = _.flatMap([snakeQ.stages]);
  snakeQ["timeframes[]"] = snakeQ.timeframes;
  snakeQ["underwriters[]"] = _.flatMap([snakeQ.underwriters]);
  snakeQ["years[]"] = _.flatMap([snakeQ.years]);

  delete snakeQ.auditors;
  delete snakeQ.domiciles;
  delete snakeQ.legals;
  delete snakeQ.stages;
  delete snakeQ.timeframes;
  delete snakeQ.underwriters;
  delete snakeQ.years;

  return queryString.stringify(snakeQ);
}

export function encodeSpacTimeframes(timeframes: Object) {
  // parse timeframes object into single dimensional array
  // in format of key(sort column)-value(month interval)
  return _.chain(timeframes)
    .toPairs()
    .flatMap((t: [string, number]) => t.join("-"))
    .value();
}

export function isEnterpriseRole(user: null | User) {
  return !_.chain(user?.roles).find(["name", "Enterprise"]).isNil().value();
}

export function makeCompaniesSearchQuery(): CompaniesSearchQuery {
  return {
    boardAvgAgeMax: 100,
    boardAvgAgeMin: 20,
    boardAvgTenureMax: 60,
    boardAvgTenureMin: 0,
    boardPctFemaleMax: 100,
    boardPctFemaleMin: 0,
    cashEquivalentsMax: undefined,
    cashEquivalentsMin: undefined,
    cashExpenditureMax: undefined,
    cashExpenditureMin: undefined,
    cashFromOperationsMax: undefined,
    cashFromOperationsMin: undefined,
    ceoAgeMax: 91,
    ceoAgeMin: 0,
    ceoRatingMax: ratings.length - 1,
    ceoRatingMin: 0,
    ceoTenureMax: 51,
    ceoTenureMin: 0,
    cfoAgeMax: undefined,
    cfoAgeMin: undefined,
    cfoRatingMax: ratings.length - 1,
    cfoRatingMin: 0,
    cfoTenureMax: undefined,
    cfoTenureMin: undefined,
    city: undefined,
    companyScoreMax: ratings.length - 1,
    companyScoreMin: 0,
    companySize: [],
    dilutedEpsMax: undefined,
    dilutedEpsMin: undefined,
    ebitdaMax: undefined,
    ebitdaMin: undefined,
    esgOverallMax: esgRatings.length - 1,
    esgOverallMin: 0,
    esgEnvironmentalMax: 10,
    esgEnvironmentalMin: 0,
    esgSocialMax: 10,
    esgSocialMin: 0,
    esgGovernanceMax: 10,
    esgGovernanceMin: 0,
    flag: [],
    grossProfitMax: undefined,
    grossProfitMin: undefined,
    industry: [],
    isActive: true,
    isDeSpac: "",
    netIncomeMax: undefined,
    netIncomeMin: undefined,
    newJoiners: 0,
    order: "asc",
    orderBy: "company_name",
    page: 1,
    perPage: 50,
    priceEarningsMax: undefined,
    priceEarningsMin: undefined,
    recentExits: 0,
    sector: [],
    state: undefined,
    stateIncorp: undefined,
    totalDebtMax: undefined,
    totalDebtMin: undefined,
    totalRevenueMax: undefined,
    totalRevenueMin: undefined,
    tsrInterval: undefined,
    tsrMin: undefined,
    tsrMax: undefined,
    type: "companies",
    zip: undefined,
    zScoreMin: undefined,
    zScoreMax: undefined,
  };
}
export function makeDocSearchQuery(): DocSearchQuery {
  return {
    companies: [],
    dateRange: "",
    topics: [],
    types: [],
    searchTerm: "",
  };
}

export function makeSpacQuery(): SpacQuery {
  return {
    keyword: "",
    order: "asc",
    orderBy: "spac_name",
    page: 1,
    perPage: 75,
    sectors: [],
    stages: [],
    underwriters: [],
    view: "full",
  };
}

export function makePeopleSearchQuery(): PeopleSearchQuery {
  return {
    ageMax: 91,
    ageMin: 20,
    alphaMax: ratings.length - 1,
    alphaMin: 0,
    bioSearch: "",
    city: undefined,
    committee: "",
    companiesMax: 11,
    companiesMin: 0,
    companySize: [],
    esgOverallMax: esgRatings.length - 1,
    esgOverallMin: 0,
    esgEnvironmentalMax: 10,
    esgEnvironmentalMin: 0,
    esgSocialMax: 10,
    esgSocialMin: 0,
    esgGovernanceMax: 10,
    esgGovernanceMin: 0,
    gender: undefined,
    industry: [],
    isCurrent: "",
    order: "asc",
    orderBy: "person_name",
    page: 1,
    perPage: 20,
    race: [],
    role: undefined,
    sector: [],
    state: undefined,
    stateIncorp: undefined,
    tsrMax: undefined,
    tsrMin: undefined,
    type: "people",
    yearsMax: 41,
    yearsMin: 0,
    zip: undefined,
  };
}

export function compensationRounder(
  value: null | number | string | undefined
): string {
  if (!value) return "-";
  if (typeof value === "string") return value;

  const precision = value >= 1e5 ? 1 : 0;

  if (value >= 1e12) {
    // 1,000,000,000,000+
    return "$" + (value / 1e12).toFixed(precision) + "T";
  } else if (value >= 1e9) {
    // 1,000,000,000 to 9,999,999,999
    return "$" + (value / 1e9).toFixed(precision) + "B";
  } else if (value >= 1e6) {
    // 1,000,000 to 999,999,999
    return "$" + (value / 1e6).toFixed(precision) + "M";
  } else if (value >= 1e5) {
    // 100,000 to 999,999 (treat as M, i.e. 0.6M)
    return "$" + (value / 1e6).toFixed(precision) + "M";
  } else if (value >= 1e3) {
    // 1,000 to 99,999
    return "$" + (value / 1e3).toFixed(precision) + "K";
  } else {
    return currencyFormatter.format(value);
  }
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: "currency",
});

export function esSafeInput(s: string): string {
  return s.replace(/[^a-zA-Z0-9 &,$.-]/g, "");
}

export function enableMarker(data: Array<any>): boolean {
  if (data.length > 1) return false;
  else return true;
}

export function spacReturnsFormatter(
  r: null | number | string | undefined,
  noUnit?: boolean
): string {
  if (!r && r !== 0) return "-";
  if (typeof r === "string") return r;
  return noUnit ? r.toFixed(1) : r.toFixed(1).concat("%");
}

export const stockPriceFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: "currency",
});

export function largeCurrencyFormatter(
  value: number | null | string | undefined,
  precision = 1,
  axis = false,
  includeDollarSign = true
): string {
  if (value || (axis && value === 0)) {
    const number = Math.abs(value as any);
    if (isNaN(number)) return (value as string) || "-";
    let formatted: string = "";
    if (number >= 1e12) {
      formatted = (number / 1e12).toFixed(precision) + "T";
    } else if (number >= 1e9) {
      formatted = (number / 1e9).toFixed(precision) + "B";
    } else if (number >= 1e5) {
      formatted = (number / 1e6).toFixed(precision) + "M";
    } else {
      if (includeDollarSign) return currencyFormatter.format(number);
      formatted = number.toString();
    }
    if (includeDollarSign) formatted = "$" + formatted;
    return (typeof value === "string" ? parseFloat(value) : value) > 0
      ? formatted
      : "-" + formatted;
  }
  return "-";
}

/*
 Based on the largeCurrencyFormatter() function, this one uses slightly different
 logic in order to support displaying "K" units (thousands).
*/
export function largeCurrencyFormatterWithThousands(
  value: number | null | string | undefined,
  precision = 1,
  axis = false,
  includeDollarSign = true
): string {
  if (value || (axis && value === 0)) {
    const number = Math.abs(value as any);
    if (isNaN(number)) return (value as string) || "-";
    let formatted: string = "";
    if (number >= 1e12) {
      formatted = (number / 1e12).toFixed(precision) + "T";
    } else if (number >= 1e9) {
      formatted = (number / 1e9).toFixed(precision) + "B";
    } else if (number >= 1e6) {
      formatted = (number / 1e6).toFixed(precision) + "M";
    } else if (number >= 1e3) {
      formatted = (number / 1e3).toFixed(0) + "K";
    } else {
      if (includeDollarSign) return currencyFormatter.format(number);
      formatted = number.toString();
    }
    if (includeDollarSign) formatted = "$" + formatted;
    return (typeof value === "string" ? parseFloat(value) : value) > 0
      ? formatted
      : "-" + formatted;
  }
  return "-";
}

export function formatToUnits(n: number, precision: number) {
  const abbrev = ["", "K", "M", "B", "T"];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(n)) / 3);
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
  const suffix = abbrev[order];

  return (n / Math.pow(10, order * 3)).toFixed(precision) + suffix;
}

export function pluralize(count: number, noun: string, suffix = "s") {
  return `${count} ${noun}${count !== 1 ? suffix : ""}`;
}

export function stringifySearchQuery(
  q: SearchQuery | Partial<SpacQuery>
): string {
  const s = { ...(q as any) };
  return queryString.stringify(s);
}

export function stringifyDocQuery(q: DocSearchQuery): string {
  const s = { ...(q as any) };
  s.companies = s.companies.map((c: any) => JSON.stringify(c));
  return queryString.stringify(s);
}

export function stringifyFilingsMonitorQuery(q: FilingsMonitorQuery): string {
  const snakeQ: any = _.chain(q)
    .omitBy(_.isNil)
    .mapKeys((value, key) => _.snakeCase(key))
    .value();

  snakeQ["filing_types[]"] = _.flatMap([snakeQ.filing_types]);
  snakeQ["sectors[]"] = _.flatMap([snakeQ.sectors]);
  snakeQ["sizes[]"] = _.flatMap([snakeQ.sizes]);
  snakeQ["topics[]"] = _.flatMap([snakeQ.topics]);

  delete snakeQ.filing_types;
  delete snakeQ.sectors;
  delete snakeQ.sizes;
  delete snakeQ.topics;

  return queryString.stringify(snakeQ);
}

export function parseCompaniesSearchQuery(s: string): CompaniesSearchQuery {
  const qs = _.mapValues(queryString.parse(s), (v) => {
    if (v instanceof Array) {
      return (v as string[]).map((s) => numerize(s));
    }

    return numerize(v as string);
  });

  // qs.industries = _.flatMap([qs.industries]);
  return {
    ...makeCompaniesSearchQuery(),
    ...qs,
  };
}

export function parseCopilotCitation(str: string): string {
  const sources: string[] = [];
  const reg = /([([]*(?:source(?:_id)?[: ]*)?\d{10}-\d{2}-\d{6}[)\]]*)/gi;
  return str.replace(reg, ($0) => {
    const ean = _.first($0.match(/\d{10}-\d{2}-\d{6}/)) as string;
    let index: number = sources.indexOf(ean);
    if (index === -1) index = sources.push(ean) - 1;
    return `<a class='font-semibold text-blue underline' href='/feed/sec/${ean}' target='_blank'>[${index + 1
      }]</a>`;
  });
}

export function parseDocSearchQuery(s: string): DocSearchQuery {
  const qs = _.mapValues(queryString.parse(s), (v) => {
    return v;
  });

  qs.types = !!qs.types ? _.flatMap([qs.types]) : [];
  qs.topics = !!qs.topics ? _.flatMap([qs.topics]) : [];
  qs.companies = !!qs.companies ? _.flatMap([qs.companies]) : [];
  qs.companies = (qs.companies as string[]).map((c) => JSON.parse(c));
  return {
    ...makeDocSearchQuery(),
    ...qs,
  };
}

export function parsePeopleSearchQuery(s: string): PeopleSearchQuery {
  const qs = _.mapValues(queryString.parse(s), (v) => {
    if (v instanceof Array) {
      return (v as string[]).map((s) => numerize(s));
    }
    return numerize(v as string);
  });

  qs.industries = _.flatMap([qs.industries]);
  qs.race = _.flatMap([qs.race]);
  return {
    ...makePeopleSearchQuery(),
    ...qs,
  };
}

export function parseRecurlyQuery(s: string): RecurlyQuery {
  const baseRecurlyQuery = {
    account: "",
    plan: "",
  };
  const qs = _.mapValues(queryString.parse(s));
  return {
    ...baseRecurlyQuery,
    ...qs,
  };
}

export function parseSpacSearchQuery(s: string, view?: string): SpacQuery {
  const qs = _.mapValues(queryString.parse(s), (v) => {
    if (v instanceof Array) {
      return (v as string[]).map((s) => numerize(s));
    }
    if (!!view && ["announced", "pre_trans"].includes(view)) return v as string;
    return numerize(v as string);
  });
  switch (view) {
    case "announced":
      qs.stages = "Announced Transaction";
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "merger_announced_date";
      break;
    case "calendar":
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "date";
      break;
    case "despac":
      qs.stages = "De-SPAC";
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "despac_date";
      break;
    case "liquidations":
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "end_date";
      break;
    case "pre_ipo":
      qs.stages = "Pre-IPO";
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "initial_s1";
      break;
    case "pre_trans":
      qs.stages = "Pre-Transaction";
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "start_date";
      break;
    case "sponsor":
      qs.stage = "Sponsors";
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "tot_cnt";
      break;
    case "underwriter":
      qs.stage = "Underwriters";
      qs.order = qs.order || "desc";
      qs.orderBy = qs.orderBy || "tot_cnt";
      qs.years = qs.years || [dayjs().year()];
      break;
    default:
      break;
  }
  qs.auditors = _.flatMap([qs.auditors]);
  qs.legals = _.flatMap([qs.legals]);
  qs.stages = _.flatMap([qs.stages]);
  // flatMap for single timeframes filtering
  // compact to remove null || undefined values
  // for proper application of timeframes.length check
  qs.timeframes = _.chain([qs.timeframes]).flatMap().compact().value();
  qs.underwriters = _.flatMap([qs.underwriters]);
  qs.years = _.flatMap([qs.years]);
  qs.view = view || "full";

  // if timeframes filter array is present
  if (!!(qs.timeframes as string[])?.length) {
    const timeframes: { [key: string]: number } = {};
    // each element is in format of key(sort column)-value(month interval)
    // parse timeframes filter array via loop by
    // splitting element on '-' = [key(sort column), value(month interval)]
    // assigning timeframes obj w/ key of sort column and value of month interval
    _.forEach(qs.timeframes as string[], (t: string) => {
      const arr: string[] = t.split("-");
      timeframes[arr[0]] = parseInt(arr[1]);
    });
    qs.timeframes = timeframes as any;
  }

  const q: any = {
    ...makeSpacQuery(),
    ...qs,
  };
  if (["calendar", "sponsor", "underwriter"].includes(view || "")) {
    if (view !== "calendar") {
      if (view === "sponsor") {
        q.threshold = qs.threshold;
        q.window = qs.window;
      }
      q.order = qs.order || "desc";
      q.orderBy = qs.orderBy || "tot_cnt";
    }
    if (view === "calendar") {
      delete q.page;
      delete q.perPage;
    }
  }
  return q;
}

export function snakeCase(query: any) {
  return _.chain(query)
    .pickBy((v) => !!v)
    .mapKeys((_values, key) => _.snakeCase(key))
    .value();
}

export function stringifyPrivateIds(ids: string[]) {
  return queryString.stringify({ ids: ids });
}

export function numerize(s: string | number): string | number {
  if (typeof s === "number") return s;

  const parsed = Number.parseInt(s, 10);
  if (Number.isNaN(parsed)) {
    return s;
  }
  return parsed;
}

export function niceNumber(
  n: null | number | undefined,
  suffix?: string,
  axis = false
): string {
  if (n || (n === 0 && axis)) {
    const val = Object.is(Math.round(n), -0) ? 0 : Math.round(n);
    return val.toLocaleString() + (suffix ? suffix : "");
  }
  return "-";
}

export function niceNumberSometimes(
  n: number | string | undefined,
  upper_threshold: number,
  lower_threshold?: number,
  suffix?: string
): string {
  if (typeof n === "string") return n || "-";
  if (
    n &&
    n <= upper_threshold &&
    (typeof lower_threshold === "undefined" || n >= lower_threshold)
  ) {
    return n.toFixed(1) + (suffix ? suffix : "");
  } else return niceNumber(n, suffix);
}

export function formatBADate(date: BADate): string {
  const mstring = String(date.month).padStart(2, "0");
  const dstring = String(date.day).padStart(2, "0");
  return `${date.year}-${mstring}-${dstring}`;
}

export function formatFormDDate(dateString: string) {
  let date = dayjs(dateString.slice(0, 10));
  return date.format("MM/DD/YYYY");
}

export function getRoleString(roleStrings: string[][], addSpace?: boolean) {
  if (!roleStrings.length) return "";
  const joinChar = addSpace ? " / " : "/";
  return roleStrings[0].join(joinChar);
}

export function formatPhoneNumber(phoneNumberString: string) {
  const numArr = phoneNumberString.split(/e?x/i);
  const extension = numArr.length > 1 ? "Ex " + numArr[1].toLowerCase() : "";
  const numericString = numArr[0].replace(/\D/g, "");
  if (numericString.length === 10) {
    return `${numericString.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      "($1) $2-$3"
    )} ${extension}`;
  } else {
    return phoneNumberString;
  }
}

export function parseBADate(date: string): BADate {
  const m = date.match(/(\d+)-(\d+)-(\d+)/) || [""];
  return {
    day: parseInt(m[3], 10),
    month: parseInt(m[2], 10),
    year: parseInt(m[1], 10),
  };
}

export function safePublicPath(
  id: number | string,
  isPublic: boolean | undefined,
  source: "companies" | "people"
) {
  let basePath: string = "";
  if (isPublic) basePath += "/profiles";
  return `${basePath}/${source}/${id}`;
}

export function popupPosition(
  spaceToRightBoundary: number,
  popupWidth: number
) {
  if (
    popupWidth - spaceToRightBoundary > 0 &&
    popupWidth - spaceToRightBoundary < popupWidth / 2
  )
    return "bottom center"; //popup only exceeds right boundary by a small amount, open downwards
  if (popupWidth > spaceToRightBoundary) return "left top";
  return "right top";
}

export function safeToFixed(
  val: null | number | string | undefined,
  param: number
) {
  if (!val) return null;
  const float = parseFloat(val as string);
  if (!float) return val;
  return float.toFixed(param);
}
export default {
  compensationRounder,
  currencyFormatter,
  downloadFile,
  enableMarker,
  encodeDiversitySearchQuery,
  encodeSpacQuery,
  formatBADate,
  largeCurrencyFormatter,
  makePeopleSearchQuery,
  niceNumber,
  niceNumberSometimes,
  parseBADate,
  parsePeopleSearchQuery,
  safePublicPath,
  safeToFixed,
};
