import { baseURL, ApiData, ApiMetaData } from "./api";
import dataUtils from "./dataUtils";
import {
  AppOptions,
  SpacQuery,
  SearchResult,
  FilingsMonitorQuery,
  FilingsMonitorFiling,
  PaginationData,
} from "./types";
import { encodeSpacQuery, stringifyFilingsMonitorQuery } from "./utils";

function publicBaseUrl() {
  if (window.location.hostname === "localhost") {
    return window.location.port === "3000"
      ? "https://stage-api.boardroomalpha.com/api/public"
      : "http://localhost:3000/api/public";
  }
  const apiHost = window.location.hostname.replace(
    /^(.+)\.(\w+)\.(\w+)$/,
    "$1-api.$2.$3"
  );
  return `https://${apiHost}/api/public`;
}
async function publicApiFetch(path: string, init?: RequestInit) {
  const fullInit: RequestInit = {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      "X-Forwarded-Proto": window.location.port === "3001" ? "http" : "https",
    },
    ...init,
  };
  const res = await fetch(publicBaseUrl() + path, fullInit);
  return res.json();
}
export async function getCompanyMeta(id: string) {
  return await publicApiFetch(`/companies/${id}`);
}
export async function getFilings(
  query: FilingsMonitorQuery,
  signal: AbortSignal
): Promise<ApiMetaData<FilingsMonitorFiling[], PaginationData>> {
  return await publicApiFetch(
    `/tools/filings?${stringifyFilingsMonitorQuery(query)}`,
    { signal }
  );
}
export async function getFilingSourceURL(params: { ean: string }) {
  return await publicApiFetch(`/filings/${params.ean}`);
}
export async function getPersonMeta(id: string) {
  return await publicApiFetch(`/people/${id}`);
}

export async function apiFetch(path: string, init?: RequestInit) {
  const fullInit: RequestInit = {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      "X-Forwarded-Proto": window.location.port === "3001" ? "http" : "https",
    },
    ...init,
  };
  const res = await fetch(baseURL() + path, fullInit);
  return res.json();
}

export async function getCompany(id: number | string) {
  const company = await apiFetch(`/public/companies/${id}`);
  if (!!company.data?.stock_prices?.length) {
    for (const price of company.data.stock_prices) {
      price.price_date = dataUtils.toEastCoastDate(price.price_date as any);
    }
  }
  return company;
}

export async function getNames(
  query: string
): Promise<ApiData<SearchResult[]>> {
  return await apiFetch(`/public/names?q=${query}`);
}

export async function getPerson(id: string) {
  return await apiFetch(`/public/people/${id}`);
}

export async function getPublicAppOptions(): Promise<ApiData<AppOptions>> {
  return await apiFetch("/public/option_values");
}

export async function getSpacList(query: SpacQuery, signal: AbortSignal) {
  const q = encodeSpacQuery(query);
  return await apiFetch(`/public/spac_analytics/full?${q}`, { signal });
}

export async function getInsiderTrading(isCompany: boolean, params: any) {
  if (!!params.category) {
    let path = `/public/tracker_data?date_range=${params.date_range}&view_type=`;
    // isCompany (company name/ticker search)
    path += isCompany
      ? `${params.category}&term=${params.value}`
      : `group_by&${params.category}=${params.value}`;
    return await apiFetch(path);
  } else {
    // default search: Large Market Cap Year to date
    return await apiFetch(
      `/public/tracker_data?date_range=Year%20to%20date&view_type=group_by&marketcap=5`
    );
  }
}

export async function searchCompanyNames(
  query: string
): Promise<ApiData<SearchResult[]>> {
  return await apiFetch(`/public/company_names?q=${query}`);
}
