import "rc-slider/assets/index.css";
import { lazy, Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import api from "./api";
import { getPublicAppOptions } from "./apiPublic";
import LoadingIndicator from "./Components/LoadingIndicatorV2";
import PrintDisclaimer from "./Components/PrintDisclaimer";
import { AppOptions } from "./types";

const ExternalRouter = lazy(() => import("./Routes/ExternalRouter"));
const InternalRouter = lazy(() => import("./Routes/InternalRouter"));

function gaTrackingID() {
  switch (window.location.hostname) {
    case "app.boardroomalpha.com":
      return "UA-137783410-1";
    case "preprod.boardroomalpha.com":
      return "UA-137783410-2";
    default:
      return "UA-137783410-3";
  }
}

export function getBrowserBaseURL() {
  return window.location.origin;
}

if (!process.env.JEST_WORKER_ID) {
  ReactGA.initialize(gaTrackingID());
}

const tagManagerArgs = {
  gtmId: "GTM-KBRT5BM",
};

TagManager.initialize(tagManagerArgs);

export const loading: JSX.Element = (
  <div className="flex flex-col h-screen justify-center">
    <LoadingIndicator fullScreen />
  </div>
);

function App() {
  const location = useLocation();
  const [appOptions, setAppOptions] = useState<undefined | AppOptions>();
  const [spacLoading, setSpacLoading] = useState<boolean>(true);
  const isLarge = useMediaQuery({ minDeviceWidth: 768 });
  const authenticated: boolean = !!localStorage.authToken;

  useEffect(() => {
    async function fetchAppOptions() {
      const res = authenticated
        ? await api.getAppOptions()
        : await getPublicAppOptions();
      setAppOptions(res.data);
    }
    fetchAppOptions();
  }, [authenticated]);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <Suspense fallback={loading}>
      {authenticated ? (
        <InternalRouter
          appOptions={appOptions}
          isLarge={isLarge}
          spacLoading={spacLoading}
          setSpacLoading={setSpacLoading}
        />
      ) : (
        <ExternalRouter
          appOptions={appOptions}
          isLarge={isLarge}
          spacLoading={spacLoading}
          setSpacLoading={setSpacLoading}
        />
      )}
      <PrintDisclaimer />
    </Suspense>
  );
}

export default App;
