import { configureStore } from "@reduxjs/toolkit";
import optionReducer from "./reducers/optionSlice";
import userReducer from "./reducers/userSlice";
import { Collection, UserPeerGroup } from "./types";

export interface UserOption {
  collections: Collection[];
  userPeerGroups: UserPeerGroup[];
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: { option: optionReducer, user: userReducer },
});
