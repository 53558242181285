export default function PrintDisclaimer() {
  return (
    <div className="bounded-width p-12 print-break-before-always screen-hidden space-y-5">
      <h2 className="text-red text-xl">Disclaimer</h2>
      <p>
        The opinions and information contained herein have been obtained or
        derived from sources believed to be reliable, but Boardroom Alpha ("BA")
        cannot guarantee its accuracy and completeness, and that of the opinions
        based thereon.
      </p>
      <p>
        This report contains opinions and is provided for informational purposes
        only. You should not rely solely upon the research herein for purposes
        of transacting securities or other investments, and you are encouraged
        to conduct your own research and due diligence, and to seek the advice
        of a qualified securities professional, before you make any investment.
      </p>
      <p>
        None of the information contained in this report constitutes or is
        intended to constitute a recommendation by BA of any particular security
        or trading strategy or a determination by BA that any security or
        trading strategy is suitable for any specific person.
      </p>
      <p>
        To the extent any of the information contained herein may be deemed to
        be investment advice, such information is impersonal and not tailored to
        the investment needs of any specific person.
      </p>
      <p className="font-bold">
        Your use of this report is governed by the Terms of Service defined at{" "}
        <a
          className="text-blue underline"
          href="https://www.boardroomalpha.com/tos"
        >
          https://www.boardroomalpha.com/tos
        </a>
      </p>
      <p>
        If you do not have access to the application, you can request the Terms
        of Service by emailing{" "}
        <a
          className="text-blue underline"
          href="mailto:customersuccess@boardroomalpha.com"
        >
          customersuccess@boardroomalpha.com
        </a>
      </p>
    </div>
  );
}
