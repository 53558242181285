import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { concat } from "lodash";
import api from "../api";
import { UserSubscriptionAlert } from "../types";

const initialState: any = null;

export const fetchUserPayload = createAsyncThunk(
  "user/fetchUserPayload",
  async () => {
    return await api.getUserPayload();
  }
);
export const updateUserSubscription = createAsyncThunk(
  "user/updateUserSubscription",
  async (subscription: keyof UserSubscriptionAlert) => {
    const res = await api.updateUserSubscription(subscription);
    return res.user_subscription;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addApiKey(state, action) {
      return {
        ...state,
        api_keys: concat(state.api_keys, action.payload.data),
        exceed_max_generated_api_keys:
          action.payload.exceed_max_generated_api_keys,
        exceed_max_active_api_keys: true,
      };
    },
    decrementFollowCnt(state) {
      const cnt = state.follow_cnt - 1;
      return {
        ...state,
        exceed_max_follows: !state.max_follows
          ? false
          : cnt > state.max_follows,
        follow_cnt: cnt,
      };
    },
    incrementFollowCnt(state) {
      const cnt = state.follow_cnt + 1;
      return {
        ...state,
        exceed_max_follows: !state.max_follows
          ? false
          : cnt > state.max_follows,
        follow_cnt: cnt,
      };
    },
    initialize(state, action) {
      return action.payload;
    },
    updateApiKeyStatus(state, action) {
      return {
        ...state,
        api_keys: state.api_keys.map((k: any) =>
          k.id === action.payload.id ? { ...k, enabled: !k.enabled } : k
        ),
        exceed_max_active_api_keys: action.payload.exceed_max_active_api_keys,
      };
    },
    updateLandingPage(state, action) {
      return {
        ...state,
        landing_page: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserPayload.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(updateUserSubscription.fulfilled, (state, action) => {
      return {
        ...state,
        alerts: action.payload,
      };
    });
  },
});

export const {
  addApiKey,
  decrementFollowCnt,
  incrementFollowCnt,
  initialize,
  updateApiKeyStatus,
  updateLandingPage,
} = userSlice.actions;

export default userSlice.reducer;
