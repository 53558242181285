// tslint:disable: object-literal-sort-keys
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import _, { Dictionary } from "lodash";
import {
  ChartPoint,
  CollatedDailyHolding,
  Company,
  CompanyDetail,
  DailyHoldingPoint,
  DailyHoldings,
  DirectorCompensation,
  ExecutiveCompensation,
  GraphPoint,
  MarketSummary,
  Metric,
  MsciRating,
  PerformanceRelativePoint,
  Person,
  PersonCompanyDetail,
  PersonDetail,
  PurchaseActivity,
  Score,
  Source,
  StockActivityRow,
  StockPrice,
  TabulatedCompensation,
  TabulatedCompensationItem,
  TimeInterval,
  VoteCounts,
  VotingResult,
} from "./types";
import utils, { stockPriceFormatter } from "./utils";

dayjs.extend(quarterOfYear);

function dailyHoldingsPeople(holdings: DailyHoldings, onlyCurrent: boolean) {
  return _.chain(holdings)
    .values()
    .flatten()
    .map((v) => v.person)
    .uniqBy((v) => v.cik)
    .sortBy((v) => v.name)
    .filter((v) => {
      return v.name !== null && (onlyCurrent ? v.is_current : true);
    })
    .value();
}

function dailyHoldingsTickers(holdings: DailyHoldings) {
  return _.chain(holdings)
    .values()
    .flatten()
    .map((v) => v.ticker)
    .uniq()
    .sort()
    .value();
}

function formatDate(date: string | null | number | undefined, format: string) {
  if (!date) return "-";
  return dayjs(date).format(format);
}

function rollupHoldings(
  holdings: Array<{
    data: DailyHoldingPoint[][];
    name: string;
    prices: StockPrice[];
  }>,
  yearly: boolean
): Array<{ name: string; data: any }> {
  const year = dayjs().subtract(1, "year");
  let monthlyHoldings: Array<any> | undefined = undefined;

  const rollup = holdings.map((holding) => {
    const data = holding.prices.map((price) => {
      let sum = 0;
      for (const h of holding.data) {
        // Use binary search here for speed.
        const idx = _.sortedIndexBy(
          h,
          { eod_date: price.price_date } as Partial<DailyHoldingPoint>,
          (v) => v.eod_date
        );
        if (idx > 0 && idx - 1 < h.length) {
          sum += h[idx - 1].tot_shares * price.close;
        }
      }
      return [price.price_date, Math.round(sum)] as ChartPoint;
    });

    if (yearly) {
      monthlyHoldings = _.chain(data)
        .filter((d) => dayjs(d[0]).isAfter(year))
        .groupBy((d) => dayjs(d[0]).month())
        .mapValues((d) => d[d.length - 1])
        .values()
        .orderBy((d) => dayjs(d[0]), "desc")
        .value();
    }

    return {
      data: _.dropWhile(monthlyHoldings || data, (d) => d[1] === 0),
      name: holding.name,
    };
  });
  return rollup.filter((r) => r.data.length > 0);
}

function collateDailyHoldings(
  holdings: DailyHoldings,
  prices: StockPrice[],
  onlyCurrent: boolean,
  rollup: "person" | "ticker",
  yearly: boolean = true
): CollatedDailyHolding[] {
  if (rollup === "person") {
    const keys = dailyHoldingsPeople(holdings, onlyCurrent);
    const fholdings = keys.map((k) => {
      const data = _.values(holdings).map((h) => {
        return h.filter((v) => v.person.cik === k.cik);
      });
      return { name: k.name, data, prices };
    });
    return rollupHoldings(fholdings, yearly);
  } else {
    const keys = dailyHoldingsTickers(holdings);
    const fholdings = keys.map((k) => {
      const data = _.values(holdings).map((h) => {
        return h.filter((v) => v.ticker === k);
      });
      const filteredPrices = prices.filter((p) => p.ticker === k);
      return { name: k, data, prices: filteredPrices };
    });
    return rollupHoldings(fholdings, yearly);
  }
}

function collateMarketSummaries(
  summaries: MarketSummary[],
  metric: Metric,
  type: "market" | "sic"
): PerformanceRelativePoint[] {
  return summaries.map((m) => {
    return {
      quarter: m.quarter,
      value: m.indicators[metric][type],
      year: m.year,
    };
  });
}

function collatePerformanceHistographData({
  counts,
  edges,
}: {
  counts: number[];
  edges: number[];
}): GraphPoint[] {
  return edges.map((c: number, i: number) => ({
    x: c,
    y: counts[i],
  }));
}

function collateVotes(votes: VotingResult[]) {
  const collated: {
    counts: {
      [id: string]: { [year: number | string]: VoteCounts & { ean: string[] } };
    };
    sources: { [id: string]: Source };
    totals: VoteCounts;
    years: number[];
  } = { counts: {}, sources: {}, totals: makeVoteCounts(), years: [] };
  collated.years = _.chain(votes)
    .map((v) => new Date(v.date_trunc).getFullYear())
    .uniq()
    .sort()
    .reverse()
    .value();
  for (const vote of votes) {
    const year = new Date(vote.date_trunc).getFullYear();

    collated.sources[vote.source.id] = vote.source;
    collated.counts[vote.source.id] = collated.counts[vote.source.id] || {};
    collated.counts[vote.source.id][year] =
      collated.counts[vote.source.id][year] || makeVoteCounts();
    collated.counts[vote.source.id][year].num_black += vote.num_black;
    collated.counts[vote.source.id][year].num_green += vote.num_green;
    collated.counts[vote.source.id][year].num_red += vote.num_red;
    collated.counts[vote.source.id][year].num_yellow += vote.num_yellow;
    collated.counts[vote.source.id][year].pct_vote += vote.pct_vote;
    !!collated.counts[vote.source.id][year].ean?.length
      ? collated.counts[vote.source.id][year].ean.push(
        vote.edgar_accession_number
      )
      : (collated.counts[vote.source.id][year].ean = [
        vote.edgar_accession_number,
      ]);
    collated.totals.num_black += vote.num_black;
    collated.totals.num_green += vote.num_green;
    collated.totals.num_red += vote.num_red;
    collated.totals.num_yellow += vote.num_yellow;
  }

  Object.keys(collated.counts).map((cik) => {
    return Object.keys(collated.counts[cik]).map((year: string) => {
      const numVotes =
        collated.counts[cik][year].num_black +
        collated.counts[cik][year].num_green +
        collated.counts[cik][year].num_yellow +
        collated.counts[cik][year].num_red;
      return (collated.counts[cik][year].pct_vote =
        collated.counts[cik][year].pct_vote / numVotes);
    });
  });

  return collated;
}

export function colorForMsciRating(rating: MsciRating): string {
  switch (rating) {
    case "AAA":
    case "AA":
      return "msci-leader-dot";
    case "A":
    case "BBB":
    case "BB":
      return "msci-average-dot";
    case "B":
    case "CCC":
      return "msci-laggard-dot";
    default:
      return "unrated";
  }
}

export function colorForRating(rating: string): string {
  switch (rating) {
    case "A+":
    case "A":
    case "A-":
      return "var(--color-green)";
    case "B+":
    case "B":
    case "B-":
      return "var(--color-light-blue)";
    case "C+":
    case "C":
    case "C-":
      return "var(--color-orange)";
    case "D+":
    case "D":
    case "D-":
      return "var(--color-red)";
    case "F":
      return "var(--color-purple)";
    default:
      return "#aaa";
  }
}

function colorForScore(score?: number): string {
  if (!score) return "var(--color-gray)";
  if (score < 40) return "var(--color-score-red)";
  if (score < 80) return "var(--color-score-yellow)";
  return "var(--color-score-green)";
}

function historyRatingInterval(
  history: PersonCompanyDetail
): undefined | TimeInterval {
  if (
    !history.ratings_start_year ||
    !history.ratings_start_quarter ||
    !history.ratings_start_month ||
    !history.ratings_end_year ||
    !history.ratings_end_quarter ||
    !history.ratings_end_month
  ) {
    return undefined;
  }

  return {
    end_month: history.ratings_end_month,
    end_quarter: history.ratings_end_quarter,
    end_year: history.ratings_end_year,
    start_month: history.ratings_start_month,
    start_quarter: history.ratings_start_quarter,
    start_year: history.ratings_start_year,
  };
}

function makeVoteCounts(): VoteCounts {
  return {
    num_black: 0,
    num_green: 0,
    num_red: 0,
    num_yellow: 0,
    pct_vote: 0,
  };
}

function normalizeCompany(company: CompanyDetail) {
  company.type = "company";
  const mapDirectors = _.chain(company.directors)
    .map(sourceFromPerson)
    .keyBy((s) => s.cik)
    .value();

  const mapExecutives: Dictionary<Source> = _.chain(
    company.executive_compensations
  )
    .map(sourceFromExecutiveCompensations)
    .keyBy((s: Source) => s.cik)
    .value();

  const refArray = (
    array: Array<{ person_cik: string; source: Source }>,
    map: Dictionary<Source>
  ) => {
    _.remove(array, (a) => !map[a.person_cik]);
    array.forEach((a) => (a.source = map[a.person_cik]));
  };

  refArray(company.monthly_purchase_activities, mapDirectors);
  refArray(company.monthly_purchase_activities, mapDirectors);
  refArray(company.voting_results, mapDirectors);
  refArray(company.director_compensations, mapDirectors);
  refArray(company.executive_compensations, mapExecutives);

  company.warrant_prices.forEach(
    (price: StockPrice) =>
      (price.price_date = toEastCoastDate(price.price_date as any))
  );

  normalizeSource(company);
}

function normalizePerson(person: PersonDetail) {
  person.type = "person";
  const cikMap = _.chain(person.companies)
    .keyBy((c) => c.cik_num)
    .mapValues(sourceFromCompany)
    .value();
  const tickerMap = _.chain(person.companies)
    .keyBy((c) => c.ticker)
    .mapValues(sourceFromCompany)
    .value();
  const refArray = (array: Array<{ company_cik: number; source: Source }>) => {
    _.remove(array, (a) => !cikMap[a.company_cik]);
    array.forEach((a) => (a.source = cikMap[a.company_cik]));
  };
  const normalizeNEO = (array: any[]) => {
    array.forEach((a: any) => {
      a.source = {
        cik: a.company_cik,
        id: a.company_cik,
        longName: a.company_name,
        name: a.company_name,
        ticker: a.ticker,
        type: "company",
      };
    });
  };

  if (!person.companies?.length) {
    if (!!person.executive_compensations)
      normalizeNEO(person.executive_compensations);
    if (!!person.monthly_purchase_activities)
      normalizeNEO(person.monthly_purchase_activities);
  } else {
    _.remove(person.monthly_purchase_activities, (a) => !tickerMap[a.ticker]);
    person.monthly_purchase_activities.forEach(
      (a) => (a.source = tickerMap[a.ticker])
    );
    refArray(person.executive_compensations);
  }

  refArray(person.voting_results);
  refArray(person.director_compensations);

  normalizeSource(person);
}

function normalizeSource(source: PersonDetail | CompanyDetail) {
  for (const holding of _.values(source.daily_holdings)) {
    for (const h of holding) {
      h.eod_date = toEastCoastDate(h.eod_date as any);
    }
  }

  for (const act of source.monthly_purchase_activities) {
    act.purchase_month = toEastCoastDate(act.purchase_month as any);
  }

  _.remove(source.director_compensations, (d) => d.total === 0);

  for (const price of source.stock_prices) {
    price.price_date = toEastCoastDate(price.price_date as any);
  }
}

function firstDirectorHoldingDate(director: PersonDetail): undefined | Date {
  const date = _.chain(director.daily_holdings)
    .values()
    .flatten()
    .filter((v) => v.tot_shares > 0)
    .minBy((h) => h.eod_date)
    .value();

  return date ? new Date(date.eod_date) : undefined;
}

function labelForDate(date: number): string {
  const year = Math.floor(date / 4);
  const quarter = Math.floor(date % 4) + 1;
  return `${year} Q${quarter}`;
}

function labelForDateShort(date: number): string {
  const year = Math.floor(date / 4);
  const quarter = Math.floor(date % 4) + 1;
  return `${year % 2000} Q${quarter}`;
}

function labelForMonth(date: number): string {
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = Math.floor(date / 12);
  const month = Math.floor(date % 12);
  return `${months[month]} ${year}`;
}

function labelForYear(date: number): string {
  const year = Math.floor(date / 4);
  return `${year}`;
}

function safePrice(price: null | number | undefined) {
  if (!price) return "-";
  return stockPriceFormatter.format(price);
}

function safeLastGrade(scores: Score[]) {
  if (scores.length > 0) return _.last(scores)!.score_grade || "-";
  return "-";
}

function safeLastScore(scores: Score[]) {
  if (scores.length > 0) return _.last(scores)!.score_value || undefined;
  return undefined;
}

function scoresToPoints(scores: Score[]): ChartPoint[] {
  return scores.map((s) => {
    return [
      quarterToDate(s.year, s.quarter),
      Math.round(s.score_value),
    ] as ChartPoint;
  });
}

// Pack year & quarter as number of quarters for graphing purposes.
function quarterToDate(year: number, quarter: number): number {
  return year * 4 + quarter - 1;
}

function monthToDate(year: number, month: number): number {
  return year * 12 + month - 1;
}

function dateToMonth(date: number): string {
  const year = Math.floor(date / 12);
  const month = date % 12;
  return months_short[month] + " " + year;
}

function sourceFromCompany(company: Company): Source {
  return {
    cik: company.cik_num,
    id: company.id,
    longName: company.name,
    name: company.name,
    ticker: company.ticker,
    type: "company",
  };
}

function sourceFromPerson(person: Person): Source {
  return {
    cik: person.cik_num,
    id: person.id,
    longName: person.name,
    name: person.name,
    type: "person",
  };
}

function sourceFromExecutiveCompensations(
  person: ExecutiveCompensation
): Source {
  return {
    cik: person.person_cik,
    id: person.person_cik,
    longName: person.name,
    name: person.name,
    type: "person",
  };
}

function addCompensation(
  tabbed: TabulatedCompensationItem,
  comp: DirectorCompensation | ExecutiveCompensation
) {
  // TODO: Make this more typesafe.
  Object.entries(comp).forEach(([k, v]) => {
    if (typeof v === "number") {
      (tabbed as any)[k] = (tabbed as any)[k] || 0;
      (tabbed as any)[k] += v;
    }
  });
}

export function randomRating() {
  return _.sample(ratings) || "A";
}

export function numberToRating(n: number) {
  return ratings[n];
}

export function ratingToNumber(rating: string) {
  return ratings.indexOf(rating);
}

export const ratings = [
  "F",
  "D",
  "C-",
  "C",
  "C+",
  "B-",
  "B",
  "B+",
  "A-",
  "A",
  "A+",
];

export const esgRatings = ["CCC", "B", "BB", "BBB", "A", "AA", "AAA"];
export function numberToEsgRating(n: number) {
  return esgRatings[n];
}

export function safePct(
  val: null | number | undefined,
  precision: number = 0
): string {
  if (!val) return "-";
  if (typeof val === "string") return val;
  return val.toFixed(precision) + "%";
}

function tabulateCompensation(
  compensation: DirectorCompensation[]
): TabulatedCompensation {
  const tabItem = {
    bonus: 0,
    fees: 0,
    ltpa: 0,
    non_equity_incentive: 0,
    options: 0,
    other: 0,
    pension_deferred_comp: 0,
    psu_rsu: 0,
    salary: 0,
    stock: 0,
    total: 0,
  };
  const r: TabulatedCompensation = {
    data: {},
    recentSourceTotals: {},
    recentTotal: { ...tabItem },
    sourceTotals: {},
    sources: [],
    total: { ...tabItem },
    yearTotals: {},
    years: [],
  };
  const maxYear = _.max(compensation.map((c) => c.year)) || dayjs().year();
  const absMinYear = _.min(compensation.map((c) => c.year)) || dayjs().year();
  const minYear = absMinYear > maxYear - 4 ? absMinYear : maxYear - 4;

  const allYears = _.chain(compensation)
    .map((c) => c.year)
    .uniq()
    .sort()
    .value();
  r.years = _.range(maxYear, minYear - 1, -1);
  const yearKeys = _.keyBy(r.years, (y) => y);
  r.sources = _.chain(compensation)
    .map((c) => c.source)
    .sortBy((c) => c.name)
    .uniqBy((c) => c.cik)
    .value();
  for (const source of r.sources) {
    r.data[source.cik] = {};
    r.recentSourceTotals[source.cik] = { ...tabItem };
    r.sourceTotals[source.cik] = { ...tabItem };
    for (const year of [...allYears, ...r.years]) {
      r.data[source.cik][year] = { ...tabItem };
    }
  }
  for (const year of [...allYears, ...r.years]) {
    r.yearTotals[year] = { ...tabItem };
  }
  for (const comp of compensation) {
    const cik = comp.source.cik;
    const year = comp.year;

    addCompensation(r.data[cik][year], comp);
    addCompensation(r.sourceTotals[cik], comp);
    addCompensation(r.total, comp);
    addCompensation(r.yearTotals[year], comp);

    if (yearKeys[year]) {
      addCompensation(r.recentSourceTotals[cik], comp);
      addCompensation(r.recentTotal, comp);
    }
  }
  return r;
}

function tabulateStockActivity(
  months: number,
  entries: Array<{ source: Source; category: string }>,
  activities: PurchaseActivity[]
): StockActivityRow[] {
  const startDate = dayjs()
    .subtract(months - 1, "month")
    .startOf("month")
    .valueOf();
  const allActivities = _.groupBy(activities, (a) => a.source.cik);
  const recentActivities = _.groupBy(
    activities.filter((a) => a.purchase_month >= startDate),
    (a) => a.source.cik
  );
  return entries.map(({ source, category }) => {
    const allActs = allActivities[source.cik] || [];
    const recentActs = recentActivities[source.cik] || [];
    const name = source.ticker || source.name;
    const totalPurchases = _.sumBy(allActs, (a) => a.total_value_p);
    const totalSales = _.sumBy(allActs, (a) => a.total_value_s);
    const deltas: any = {};
    _.range(0, months).forEach((m) => {
      const date = dayjs().subtract(m, "month").month();
      deltas[date] = 0;
    });
    recentActs.forEach((a) => {
      const date = dayjs(a.purchase_month).month();
      const value = a.total_value_p - a.total_value_s;
      deltas[date] = Math.round(value);
    });
    return {
      deltas: deltas,
      investment_category: category,
      name,
      source,
      totalPurchases,
      totalSales,
    };
  });
}

// Fix date to noon east coast time.
function toEastCoastDate(date: string): number {
  const { year, month, day } = utils.parseBADate(date);
  const ec = Date.UTC(year, month - 1, day, 16);
  return ec;
}

export const months_full: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const months_short: string[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function findQuarterYear(time: any) {
  const yearVal = dayjs(time).year();
  const quarterVal = dayjs(time).quarter();
  return { year: yearVal, quarter: quarterVal };
}
function tooltipFinancialFormatter(
  point: any,
  isCurrency: boolean,
  isQuarter: boolean,
  isPercentage: boolean,
  nonQuarterName: string = "", // optional argument for quarter charts with different time intervals.
  valueDecimals: number = 0,
  financialReview: boolean = false
) {
  const label = `<span style="color: ${point.color}">\u25CF</span>`;

  const dateTime = new Date(point.x);
  const month = dateTime.getMonth() + 1;

  const year = Math.floor(point.x / 4);
  const quarter = Math.floor(point.x % 4) + 1;

  // if time is in quraters convert month into quarter and assign to conversion,
  // else assign month array to conversion to convert month into string in date.
  let months = months_short;

  const date = `<p style="font-size: 10px">${isQuarter && point.series.name !== nonQuarterName
      ? `Q${financialReview ? quarter : month / 3} ${financialReview ? year : dateTime.getFullYear()
      }`
      : `${months[month - 1]} ${dateTime.getFullYear()}`
    }</p><br/>`;

  const value = isCurrency
    ? `<b>${point.series.name === "Avg. Close Price" ||
      point.series.name === "Stock Price"
      ? stockPriceFormatter.format(point.y)
      : utils.largeCurrencyFormatter(point.y)
    }</b>`
    : `<b>${isPercentage
      ? `${point.y.toFixed(valueDecimals)}%`
      : point.y.toFixed(valueDecimals)
    }</b>`;

  return `${date}${label} ${point.series.name}: ${value}`;
}

function tooltipFormatter(this: any) {
  return `${labelForDate(this.x)}: <b>${this.y}</b>`;
}

function tooltipPercentFormatter(this: any) {
  return `${labelForMonth(this.x)}: <b>${this.y}%</b>`;
}

function truncateStockPrices(
  prices: StockPrice[],
  director: PersonDetail
): StockPrice[] {
  const companies = _.keyBy(director.companies, (c) => c.ticker);
  return prices.filter((p) => {
    const comp = companies[p.ticker];
    if (!comp) return false;
    const date = new Date(p.price_date).toISOString();
    return date >= comp.start_date && (date <= comp.end_date || !comp.end_date);
  });
}
export default {
  collateDailyHoldings,
  collateMarketSummaries,
  collatePerformanceHistographData,
  collateVotes,
  colorForMsciRating,
  colorForRating,
  colorForScore,
  findQuarterYear,
  firstDirectorHoldingDate,
  formatDate,
  historyRatingInterval,
  labelForDate,
  labelForDateShort,
  labelForMonth,
  labelForYear,
  monthToDate,
  dateToMonth,
  normalizeCompany,
  normalizePerson,
  numberToRating,
  quarterToDate,
  ratingToNumber,
  ratings,
  safeLastGrade,
  safeLastScore,
  safePct,
  safePrice,
  scoresToPoints,
  sourceFromCompany,
  sourceFromPerson,
  tabulateCompensation,
  tabulateStockActivity,
  toEastCoastDate,
  tooltipFinancialFormatter,
  tooltipFormatter,
  tooltipPercentFormatter,
  truncateStockPrices,
};
