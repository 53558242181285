import Highcharts from "highcharts";
import "rc-slider/assets/index.css";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import ReactModal from "react-modal";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import "./tailwind.css";
import "./variables.css";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const container = document.getElementById("root"); // KPS or app?
const root = createRoot(container!);

ReactModal.setAppElement("#root");

root.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
